<template>
  <div className="container-lg">
    <table>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Educational Organization</th>
        <th>Position</th>
        <th>State</th>
        <th>Country</th>
        <th>Date Registered</th>
        <th>Disable/Enable</th>
      </tr>
      <tr v-for="admin in admins" :key="admin.idUser">
        <td>
          <router-link :to="{name: 'EditUser', params: { idUser: admin.idUser}}">{{admin.firstName}} {{admin.lastName}}</router-link>
        </td>
        <td>{{ admin.email }}</td>
        <!-- Begin ED ORG conditional -->
        <td v-if="admin.inNebraska && admin.idPosition === 1">
          ESUCC
        </td>
        <td
            v-if="
            admin.inNebraska &&
            admin.idPosition === 2 &&
            admin.districtName !== null
          "
        >
          {{ admin.districtName }}
        </td>
        <td v-else-if="admin.inNebraska && admin.idPosition === 3">
          UNL
        </td>
        <td
            v-else-if="
            admin.inNebraska &&
            (admin.idPosition === 4 || admin.idPosition === 5) &&
            admin.schoolName !== null
          "
        >
          {{ admin.schoolName }}
        </td>
        <td
            v-else-if="!admin.inNebraska && admin.outStateOrgName !== null"
        >
          {{ admin.outStateOrgName }}
        </td>
        <td
            v-else-if="!admin.inNebraska && admin.outStateOrgName === null"
        >
          No Educational Organization Currently Assigned to this User
        </td>
        <td
            v-else-if="admin.idPosition === 2 && admin.districtName === null"
        >
          No Educational Organization Currently Assigned
        </td>
        <td>{{admin.positionName}}</td>
        <!-- End Ed org mapping -->
        <td v-if="admin.stateName !== null">{{admin.stateName}}</td>
        <td v-else></td>
        <td>{{admin.countryCode}}</td>
        <td v-if="admin.registrationDate !== null">
          {{ admin.registrationDate }}</td>
        <td v-else></td>
        <td>
          <ToggleSwitch
              :isActive="admin.accountEnabled"
              @checked="toggleEnabled(admin.email, admin.accountEnabled)"
          />
        </td>

      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import {API_URL} from "../../../Constants";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      admins: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      authenticated: "auth/authenticated",
    }),
  },
  methods: {
    async getUserAdmins() {
      const post_uri = API_URL + "/user/listAdmins";
      try {
        const response = await axios.get(post_uri, {
          headers: {
            Authorization: "Bearer " + this.authenticated,
          },
        });
        response.data.sort((a, b) =>
            a.lastName > b.lastName ? 1 : -1
        );
        this.admins = response.data;

      } catch (err) {
        console.error(err);
      }
    },
    async toggleEnabled(adminEmail, adminEnabled) {
      const put_uri = API_URL + "/user/enableUser";
      try {
        const response = await axios.put(
            put_uri,
            {
              email: adminEmail,
              enabled: adminEnabled,
            },
            {
              headers: {
                Authorization: "Bearer " + this.authenticated,
              },
            }
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.user.position.idPosition !== 1) {
      window.location.replace("/login");
    }
  },
  async mounted() {
    this.getUserAdmins();
  },
};
</script>
<style scoped>
.collapsible {
  background-color: aliceblue;
  color: slategrey;
  cursor: pointer;
  padding: auto;
  text-align: left;
  outline: 1px;
  font-size: 15px;
}

.active,
.collapsible:hover {
  background-color: #ccc;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: aliceblue;
}

.card {
  position: relative;
  display: flex;
  margin: auto;
  margin-top: 50px;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  max-width: 550px;
  max-height: 250px;
  overflow-y: scroll;
}
</style>