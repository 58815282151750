<template>
  <div class="card">
    <h1>
      If a matching account was found an email was sent to the email you requested, you will receive a password change email shortly.
      <br/>
      <router-link :to="{name: 'Home'}">
        Click Here to Go Home
      </router-link>
    </h1>
  </div>
</template>
