import '@babel/polyfill'
import 'mutationobserver-shim'

/* import the fontawesome core */
import {library} from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import {faUserSecret, faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import {VueReCaptcha} from "vue-recaptcha-v3";

import axios from 'axios'
import {createApp, VueElement} from 'vue'
import App from './App.vue'
import router from './router'
import './assets/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import CountrySelect from './components/LocationSelect/CountrySelect.vue'
import DistrictSelect from './components/LocationSelect/DistrictSelect.vue'
import EsuSelect from './components/LocationSelect/EsuSelect.vue'
import StateSelect from './components/LocationSelect/StateSelect.vue'
import SchoolSelect from './components/LocationSelect/SchoolSelect.vue'
import OutNeInstitution from './components/InstitutionForm/OutNeInstitution.vue'
import MobileNav from './views/Navigation/MobileNav.vue'
import WebNav from './views/Navigation/WebNav.vue'
import ParaEducatorEnrollment from './views/ParaEducator/ParaEducatorEnrollment.vue'
import ParaEducatorList from './views/ParaEducator/ParaEducatorList.vue'
import ToggleSwitch from './components/ToggleSwitch/ToggleSwitch.vue'
import PasswordChange from './views/PasswordChange/PasswordChange.vue'
import ForgotPassword from './views/PasswordChange/UpdatePassword.vue'
import store from './store'
import Oauth from './components/Oauth/Oauth'
import tokenInterceptors from "@/Helpers/TokenInterceptors";
import validator from 'validator';
// import VueCookies from "vue-cookies";
import 'datalist-css/dist/datalist-css.js';
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'mdb-vue-ui-kit/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import '@vuepic/vue-datepicker/dist/main.css'

import 'maz-ui/css/main.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import {RECAPTCHA_SITE_KEY} from "../Constants";
import '@vueform/multiselect/themes/default.css'

import '@vueup/vue-quill/dist/vue-quill.snow.css'

VueElement.prototype.$http = axios
tokenInterceptors()
const app = createApp(App).use(store)

library.add(faUserSecret)
library.add(faChevronLeft)
library.add(faChevronRight)


app.component('CountrySelect', CountrySelect)
app.component('DistrictSelect', DistrictSelect)
app.component('EsuSelect', EsuSelect)
app.component('StateSelect', StateSelect)
app.component('SchoolSelect', SchoolSelect)
app.component('OutNeInstitution', OutNeInstitution)
app.component('MobileNav', MobileNav)
app.component('WebNav', WebNav)
app.component('ParaEducatorEnrollment', ParaEducatorEnrollment)
app.component('ParaEducatorList', ParaEducatorList)
app.component('ToggleSwitch', ToggleSwitch)
app.component('PasswordChange', PasswordChange)
app.component('ForgotPassword', ForgotPassword)
app.component('Oauth', Oauth)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(VueReCaptcha, {siteKey: RECAPTCHA_SITE_KEY})
app.use(validator)
// app.use(VueCookies)
app.use(router).mount('#app')
