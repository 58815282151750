<template>
  <div class="container-flex">
    <div v-if="user.isParaEducator">
      <!--      <Oauth />-->
      <div>
        <label>Project Para Modules</label>
      </div>
      <div>
        <a href="https://esucc.instructure.com/courses/261/pages/title-i"
        >Title I</a
        >
      </div>
      <div>
        <a
            href="https://esucc.instructure.com/courses/261/pages/special-education-requirements"
        >Special Education Requirements</a
        >
      </div>
      <div>
        <a
            href="https://esucc.instructure.com/courses/261/pages/early-child-requirements"
        >Early Childhood</a
        >
      </div>
    </div>
    <form @submit.prevent="submitEdit">
      <label for="firstName">First Name</label>
      <input type="text" name="firstName" id="firstName" v-model="firstName"/>
      <label for="lastName">Last Name</label>
      <input type="text" name="lastName" id="lastName" v-model="lastName"/>
      <label for="email">Email</label>
      <input
          type="text"
          name="email"
          id="email"
         :value="email"
          disabled
      />
      <div v-if="user.position !== null">
        <label>Position</label>
        <input type="text" disabled :value="user.position.positionName"/>
      </div>
      <div v-if="user.inNebraska">
        <EsuSelect
            :idEsu="idEsu"
            :idDistrict="idDistrict"
            :idSchool="idSchool"
            :selfRegistering="user.position.idPosition !== 1"
            :esuDisabled="user.position.idPosition !== 1"
            :distDisabled="user.position.idPosition !== 1"
            :schoolDisabled="
            user.position.idPosition === 4 || user.position.idPosition === 5
          "
            :showSchool="
            user.position.idPosition === 4 || user.position.idPosition === 5
          "

        />
      </div>
      <label
          v-if="user.inNebraska === false && user.outStateOrganization !== null"
          for="esu"
      >Educational Organization</label
      >
      <input
          v-if="user.inNebraska === false && user.outStateOrganization !== null"
          class="input-disabled"
          type="esu"
          name="esu"
          id="esu"
          :value="user.outStateOrganization.organizationName"
          disabled="disabled"
      />
      <div>
        <button
            class="button btn-info"
            @click="requestPasswordChange"
            type="reset"
        >
          Request Password Reset
        </button>
      </div>
      <div v-if="showSubmitButton">
        <button type="submit" class="button btn-primary" @click="submitEdit">
          Submit Changes
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {API_URL} from "../../../Constants";
import axios from "axios";
import EsuSelect from "@/components/LocationSelect/EsuSelect";

export default {
  components: {EsuSelect},
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
      refresh: "auth/refresh"
    }),
    showSubmitButton() {
      return (
          this.firstName !== this.originalFirstName ||
          this.lastName !== this.originalLastName ||
          this.email !== this.originalEmail
      );
    },
  },
  data() {
    return {
      daysToPasswordExpires: null,
      firstName: "",
      lastName: "",
      idUser: null,
      email: "",
      idEsu: null,
      idDistrict: null,
      idSchool: null,
      outStateOrgName: "",
      originalFirstName: "",
      originalLastName: ""
    };
  },
  watch: {
    firstName(value) {
      this.firstName = value;
    },
    lastName(value) {
      this.lastName = value;
    },
    email(value) {
      this.email = value;
    }
  },
  methods: {
    ...mapActions({
      refreshSignIn: "auth/refreshSignIn"
    }),
    redirectIfNoUser() {
      if (this.user === null) {
        window.location.replace("/login");
      }
    },
    async requestPasswordChange() {
      const request_uri =
          API_URL +
          "/user/requestPasswordChange?_email=" +
          this.user.contactEmail;

      try {
        let response = await axios.post(request_uri);
        if (response.status === 200) {
          this.$router.push({name: "PasswordChangeLanding"});
        } else {
          alert(response.data);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async submitEdit() {
      let post_uri = API_URL + "/user/editUser";

      let request_body = {
        firstName: this.firstName,
        lastName: this.lastName,
        idUser: this.user.idUser,
        email: this.email,
        idSchool: this.idSchool,
        idDistrict: this.idDistrict,
        idEsu: this.idEsu,
        outStateOrgName: this.outStateOrgName,
      };
      const response = await axios.post(post_uri, request_body, {
        headers: {
          Authorization: "Bearer " + this.authenticated,
          "Content-Type": "application/json",
        },
      });


      if (response.data === 200) {
        window.location.reload();

      }
    },
    async getUser() {
      let get_uri = API_URL + "/user/profile";
      let response = await axios.get(get_uri, {
        headers: {
          Authorization: "Bearer " + this.authenticated,
        },
      });

      this.firstName = response.data.contactFirstName;
      this.originalFirstName = response.data.contactFirstName;
      this.lastName = response.data.contactLastName;
      this.originalLastName = response.data.contactLastName;
      this.email = response.data.contactEmail;
    },
    setData() {
      if (this.user.esu) {
        this.idEsu = this.user.esu.idEsu !== null ? this.user.esu.idEsu : this.user.esu;
      } else {
        this.idEsu = -1;
      }

      if (this.user.district) {
        this.idDistrict = this.user.district.idDistrict !== null ? this.user.district.idDistrict : this.user.district;
      } else {
        this.idDistrict = -1;
      }

      if (this.user.school) {
        this.idSchool = this.user.school.idSchool !== null ? this.user.school.idSchool : this.user.school;
      } else {
        this.idSchool = -1;
      }


      this.firstName = this.user.contactFirstName;
      this.lastName = this.user.contactLastName;
      this.email = this.user.contactEmail;
    },
  },
  created() {
    this.redirectIfNoUser();
    this.getUser();
    if (this.user.esu) {
      this.idEsu = this.user.esu.idEsu !== null ? this.user.esu.idEsu : this.user.esu;
    } else {
      this.idEsu = -1;
    }

    if (this.user.district) {
      this.idDistrict = this.user.district.idDistrict !== undefined ? this.user.district.idDistrict : this.user.district;
    } else {
      this.idDistrict = -1;
    }

    if (this.user.school) {
      this.idSchool = this.user.school.idSchool !== undefined ? this.user.school.idSchool : this.user.school;
    } else {
      this.idSchool = -1;
    }
  },
  beforeCreate() {
  },
};
</script>

<style scoped>
.card {
  position: relative;
  display: flex;
  margin: auto;
  margin-top: 50px;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  max-width: 550px;
  max-height: 650px;
  overflow-y: scroll;
}

input {
  color: #000;
}

.input-disabled {
  background-color: #e5e5e5;
}

.collapsible {
  background-color: aliceblue;
  color: slategrey;
  cursor: pointer;
  padding: auto;
  text-align: left;
  outline: 1px;
  font-size: 15px;
}

.active,
.collapsible:hover {
  background-color: #ccc;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: aliceblue;
}
</style>