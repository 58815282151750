<template>
  <div>
    <Multiselect
    v-model="esuIds"
    :options="esus"
    mode="tags"
    :close-on-select="false"
    :create-option="true"
    @select="updateSelectedEsus"
    @deselect="updateSelectedEsus"
    />
  </div>
</template>

<script>
import {ref, onBeforeMount} from "vue";
import axios from "axios";
import {displayAllEsuCall} from "@/Helpers/ApiCalls/EsuAPICalls";
import Multiselect from "@vueform/multiselect/src/Multiselect";

export default {
  name: "EsuMultiSelect",
  components: {Multiselect},
  setup(props, context) {
    const isLoaded = ref(false);
    const esus = ref([]);
    const esuIds = ref([]);

    onBeforeMount(() => {
      getEsus();
    })

    async function getEsus() {
      isLoaded.value = false;

      await axios.get(displayAllEsuCall(btoa('true')))
          .then((result) => {
            esus.value = result.data.map(e => ({
              label: e.esuName,
              value: e.idEsu
            }))
          }).finally(() => {
            isLoaded.value = true;
          })
    }

    function updateSelectedEsus() {
      context.emit("selectedEsus", esuIds.value);
    }

    return {
      esus,
      esuIds,
      updateSelectedEsus
    }
  }
}
</script>

<style scoped>

</style>