<template>
  <label for="selectedDistrict">Select a Nebraska District:</label>
  <select v-model="selectedDistrict" v-bind="updateDistrict()" :disabled="distDisabled !== undefined ? distDisabled : false">
    <option value="-1" selected>Select a District</option>
    <option
        v-for="district in districts"
        v-bind:value="district.idDistrict"
        :key="district.idDistrict"
    >
      {{ district.districtName }}
    </option>

  </select>
  <div v-if="showSchool">
    <SchoolSelect
        :idDistrict="selectedDistrict"
        :idSchool="selectedSchool"
        :schoolDisabled="schoolDisabled"
        @selectedSchool="changeSchool($event)"
    />
  </div>
</template>

<script>
import axios from "axios";
import {API_URL} from "../../../Constants.js";

export default {
  props: ["idEsu", "idDistrict", "idSchool", "distDisabled", "schoolDisabled", "showSchool"],
  emits: ["selectedDistrict", "selectedSchool"],
  data() {
    return {
      districts: [],
      selectedDistrict: "-1",
      selectedSchool: 0,
      isDisabled: false,
      jobTitle: -1
    };
  },
  watch: {
    idEsu: function (value) {
      this.$props["idEsu"] = value;
      this.getDistrictsByEsu();
    },
    selectedDistrict(value) {
      this.selectedDistrict = value;
      this.updateDistrict();
    },
    idSchool(value){
      this.changeSchool(value);
    }
  },

  methods: {
    async getDistrictsByEsu() {
      const response = await axios.get(
          API_URL + "/districts/byEsu?_idEsu=" + this.$props["idEsu"]
      );
      response.data.sort((a, b) => a.districtName > b.districtName ? 1 : -1);
      this.districts = response.data;
    },
    changeSchool(value) {
      this.selectedSchool = value;
      this.$emit("selectedSchool", this.selectedSchool);
    },
    updateEsu(value) {
      this.$emit("idEsu", value);
    },
    updateDistrict() {
      this.$emit("selectedDistrict", this.selectedDistrict);
    },
  },
  created() {
    this.getDistrictsByEsu();

    if (this.idDistrict !== this.selectedDistrict) {
      this.selectedDistrict = this.idDistrict;
    } else {
      this.selectedDistrict = -1;
    }
    if (this.idSchool !== this.selectedSchool) {
      this.selectedSchool = this.idSchool;
    } else {
      this.selectedSchool = -1;
    }
  },
  mounted() {

  }
};
</script>
