<template>
  <label for="selectedSchool">Select a Nebraska School:</label>
  <select class="form-select" v-model="selectedSchool" v-bind="updateSchool()"
          :disabled="schoolDisabled !== undefined ? schoolDisabled : false">
    <option value="-1" selected>Select a School</option>
    <option
        v-for="school in schools"
        v-bind:value="school.idSchool"
        :key="school.idSchool"
    >
      {{ school.schoolName }}
    </option>
  </select>
</template>

<script>
import axios from "axios";
import {API_URL} from "../../../Constants.js";

export default {
  props: ["idDistrict", "idSchool", "schoolDisabled"],
  emits: ["selectedSchool"],
  data() {
    return {
      schools: [],
      selectedSchool: -1,
    };
  },
  watch: {
    idDistrict: function (value) {
      this.$props["idDistrict"] = value;
      this.getSchoolsByDistrict();
    },
    idSchool(value) {
      this.selectedSchool = value;
      this.updateSchool();
    }
  },
  methods: {
    async getSchoolsByDistrict() {
      const response = await axios.get(
          API_URL + "/school/byDistrict?_idDistrict=" + this.$props["idDistrict"]
      );
      response.data.sort((a, b) => a.schoolName > b.schoolName ? 1 : -1);
      this.schools = response.data;
    },
    updateSchool() {
      this.$emit("selectedSchool", this.selectedSchool);
    },
  },
  async created() {
    this.getSchoolsByDistrict();
    if (this.idDistrict !== this.selectedDistrict) {
      this.selectedDistrict = this.idDistrict;
    } else {
      this.selectedDistrict = -1;
    }
    if (this.idSchool) {
      this.selectedSchool = this.idSchool;
    } else {
      this.selectedSchool = -1;
    }



  },
};
</script>

