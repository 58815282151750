<template>
  <div v-if="jobId === 4 && authUser.idUser === idUser">
    <Oauth/>
    <br/>
    <!--  </div>-->
    <!--  <div v-if="jobId === 4">-->
    <label>Project Para Modules</label>
    <p>
      <a href="https://esucc.instructure.com/courses/261/pages/title-i">Module 1: Title I</a>
    </p>
    <p>
      <a href="https://esucc.instructure.com/courses/261/pages/special-education-requirements">Module 2: Special
        Education Requirements</a>
    </p>
    <p>
      <a href="https://esucc.instructure.com/courses/261/pages/early-child-requirements">Module 3: Early Childhood</a>
    </p>
  </div>
  <form @submit.prevent="submitEdit">
    <div class="container-flex"
         style="max-width: 750px; text-align: center; align-content: center; margin-left: auto; margin-right: auto;">
      <label for="firstName">First Name</label>
      <input
          type="text"
          name="firstName"
          id="firstName"
          v-model="firstName"
          :placeholder="user.firstName"
          :disabled="isDisabled || authUser.idUser === idUser"
      />
      <label for="lastName">Last Name</label>
      <input
          type="text"
          name="lastName"
          id="lastName"
          v-model="lastName"
          :placeholder="user.lastName"
          :disabled="isDisabled || authUser.idUser === idUser"
      />
      <label for="email">Email</label>
      <input
          type="text"
          name="email"
          id="email"
          v-model="email"
          :placeholder="user.email"
          :disabled="isDisabled"/>
      <transition name="fade">
        <span v-if="msg.email">{{ msg.email }}</span>
      </transition>
      <br v-if="msg.email"/>
      <label :for="roleHierarchyId">Job Title:</label>
      <select class="form-select" v-model="roleHierarchyId">
        <option :value="-1" selected>Job Title</option>
        <option :value="5">Paraeducator</option>
        <option :value="4" v-if="!schoolDisabled">Supervisor</option>
        <option :value="3" v-if="!districtDisabled">District Administrator</option>
        <option :value="2" v-if="!esuDisabled">ESU Administrator</option>
        <option :value="6" v-if="authUser.position.roleHierarchy === 1">NDE</option>
        <option :value="1" v-if="authUser.position.roleHierarchy === 1">ESUCC/UNL</option>
      </select>
      <div v-if="user.inNebraska">
        <EsuSelectComposition
            :idEsu="selectedEsu"
            :idDistrict="selectedDistrict"
            :idSchool="selectedSchool"
            :selfRegistering="selfRegistering"
            :showSchool="showSchoolDropdown"
            :esuDisabled="esuDisabled"
            :districtDisabled="districtDisabled"
            :showDistrict="showDistrictDropdown"
            :schoolDisabled="schoolDisabled"
            @selectedEsu="changeSelectedEsu($event)"
            @selectedDistrict="changeSelectedDistrict($event)"
            @selectedSchool="changeSchool($event)"
        />
      </div>
      <div v-else>
        <OutNeInstitution
            :institutionName="outStateOrg"
            @educationalInstitution="updateEducationalOrganization($event)"/>
      </div>
      <div>
        <button class="button button-reset btn-info" @click="requestPasswordChange" type="reset">Send a Password Reset Email
        </button>
      </div>

      <div v-if="showSubmitButton">
        <button type="submit" class="button btn-primary" @click="submitEdit">Submit Changes</button>
      </div>
    </div>
  </form>
</template>

<script>
import {mapGetters} from "vuex";
import {API_URL} from "../../../Constants";
import axios from "axios";
import ToggleSwitch from "@/components/ToggleSwitch/ToggleSwitch";
import EsuSelect from "@/components/LocationSelect/EsuSelect";
import Swal from "sweetalert2";
import OutNeInstitution from "@/components/InstitutionForm/OutNeInstitution";
import EsuSelectComposition from "@/components/LocationSelect/EsuSelectComposition";
import {
  isDistrictAdmin,
  isEsuUser,
  isInstructor, isNde,
  isParaEducatorCollegeStudentOrEarlyChildhood,
  isSuperUser,
  showDistrict,
  showSchool
} from "@/RoleCheck";

export default {
  components: {EsuSelectComposition, OutNeInstitution,/* EsuSelect*/},
  props: ["idUser"],
  data() {
    return {
      user: null,
      isDisabled: true,
      jobId: -1,
      selectedEsu: -1,
      selectedDistrict: -1,
      selectedSchool: -1,
      firstName: "",
      lastName: "",
      email: "",
      outStateOrg: "",
      selfRegistering: false,
      emailDiff: false,
      firstNameDiff: false,
      lastNameDiff: false,
      schoolDiff: false,
      districtDiff: false,
      esuDiff: false,
      roleHierarchyId: -1,
      msg: []
    }
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      authUser: "auth/user"
    }),
    showSubmitButton() {
      let email_checked = this.emailDiff && this.msg["email"] === "";
      let esu_diff = this.selectedEsu !== this.user.idEsu && this.selectedEsu !== -1;
      let district_diff = this.selectedDistrict !== this.user.idDistrict && this.selectedDistrict !== -1;
      let school_diff = this.selectedSchool !== this.user.idSchool && this.selectedSchool !== -1;
      let job_diff = this.roleHierarchyId !== this.user.roleHierarchyId && this.roleHierarchyId !== -1;
      let display = false;


      if (showSchool(this.roleHierarchyId)) {
        if (job_diff) {
          display = this.selectedSchool !== -1;
        } else if (school_diff) {
          display = true;
        }
      } else if (isNde(this.roleHierarchyId)) {
        display = (job_diff && this.selectedEsu === 22);
      } else if (isSuperUser(this.roleHierarchyId)) {
        display = job_diff && (this.selectedEsu === 20 || this.selectedEsu === 21);
      } else {
        display = esu_diff || job_diff || district_diff;
      }

      // console.log(role_cleared)
      return this.firstNameDiff || this.lastNameDiff || email_checked || display;
      /*      let email_checked = this.emailDiff || this.msg["email"] === "";
            let job_diff = parseInt(this.jobId) !== this.user.idPosition && parseInt(this.jobId) !== -1;
            let esu_diff = parseInt(this.selectedEsu) !== this.user.idEsu && parseInt(this.selectedEsu) !== -1;
            let district_diff = parseInt(this.selectedDistrict) !== this.user.idDistrict && parseInt(this.selectedDistrict) !== -1;
            let school_diff = parseInt(this.selectedSchool) !== this.user.idSchool && parseInt(this.selectedSchool) !== -1;
            let is_school_user = parseInt(this.jobId) === 4 || parseInt(this.jobId) === 5;

            let display = false;

            if (is_school_user) {
              if (job_diff) {
                if (this.user.idPosition === 4 || this.user.idPosition === 5) {
                  display = true;
                } else if (school_diff === true) {
                  display = true;
                }
              } else if (school_diff) {
                display = true;
              }
            } else {
              display = job_diff || esu_diff || district_diff;
            }

            return this.firstNameDiff || this.lastNameDiff || email_checked || display;*/
    },
    showSchoolDropdown() {
      return showSchool(this.roleHierarchyId);
    },
    showDistrictDropdown() {
      return showDistrict(this.roleHierarchyId);
    },
    esuDisabled() {
      return !isSuperUser(this.authUser.position.roleHierarchy);
    },
    districtDisabled() {
      return isDistrictAdmin(this.authUser.position.roleHierarchy) || isInstructor(this.authUser.position.roleHierarchy) || isParaEducatorCollegeStudentOrEarlyChildhood(this.authUser.position.roleHierarchy) || isNde(this.authUser.position.roleHierarchy);
    },
    schoolDisabled() {
      return isInstructor(this.authUser.position.roleHierarchy) || isParaEducatorCollegeStudentOrEarlyChildhood(this.authUser.position.roleHierarchy) || isNde(this.authUser.position.roleHierarchy);
    }
  },
  watch: {
    email(value) {
      this.email = value;
      this.emailDiff = this.user.email !== this.email;
      if (this.emailDiff){
        this.validateEmail(this.email);
      }

    },
    firstName(value) {
      this.firstName = value;
      this.firstNameDiff = this.user.firstName !== this.firstName;
    },
    lastName(value) {
      this.lastName = value;
      this.lastNameDiff = this.user.lastName !== this.lastName;
    },
    jobId(value) {
      this.jobId = value;
    },
    roleHierarchyId(value) {
      this.roleHierarchyId = value;
    }
  },
  methods: {
    canSubmit() {
      let email_checked = this.emailDiff && this.msg["email"] === "";
      let esu_diff = parseInt(this.selectedEsu) !== this.user.idEsu && parseInt(this.selectedEsu) !== -1;
      let district_diff = parseInt(this.selectedDistrict) !== this.user.idDistrict && parseInt(this.selectedDistrict) !== -1;
      let school_diff = parseInt(this.selectedSchool) !== this.user.idSchool && parseInt(this.selectedSchool) !== -1;
      let job_diff = this.roleHierarchyId !== this.user.roleHierarchyId && this.roleHierarchyId !== -1;
      let role_cleared = false;


      if (job_diff) {
        if (esu_diff) {
          role_cleared = isSuperUser(this.roleHierarchyId) || isEsuUser(this.roleHierarchyId);
        }
        if (district_diff) {
          role_cleared = isDistrictAdmin(this.roleHierarchyId);
        }
        if (school_diff) {
          role_cleared = isInstructor(this.roleHierarchyId) || isParaEducatorCollegeStudentOrEarlyChildhood(this.roleHierarchyId);
        }
        if (this.selectedEsu === 22) {
          role_cleared = isNde(this.roleHierarchyId);
        }
      }
      return this.firstNameDiff || this.lastNameDiff || email_checked || role_cleared;
    },
    async checkUserExists(value) {
      let post_url = API_URL + "/user/checkEmailExists";

      let exists = false;
      try {
        const response = await axios.get(post_url + "?_email=" + value);
        exists = response.data;
      } catch (err) {
        console.error(err);
      }
      return exists;
    },
    async validateEmail(value) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        let exists = await this.checkUserExists(value);

        if (exists === true) {
          this.msg["email"] =
              "A user with that email address is already registered!";
        } else {
          this.msg["email"] = "";
        }
      } else {
        this.msg["email"] = "Invalid Email Address Format";
      }
    },
    async getUser() {
      let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(atob(this.idUser));
      let response = await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + this.authenticated
        }
      });

      this.user = await response.data;
      this.jobId = response.data.idPosition;
      this.selectedEsu = response.data.idEsu;
      this.selectedDistrict = response.data.idDistrict;
      this.roleHierarchyId = response.data.roleHierarchyId;

      // this is a bit funky to set the default school
      // but it checks the initial job id to ensure if the user is already assigned
      // a school
      if (response.data.idSchool !== null) {
        if (this.roleHierarchyId !== 4 && this.roleHierarchyId !== 5) {
          this.selectedSchool = -1;
        } else {
          this.selectedSchool = response.data.idSchool;
        }
      } else {
        this.selectedSchool = -1;
      }

      this.outStateOrg = response.data.outStateOrg;
      this.email = response.data.email;
      this.firstName = response.data.firstName;
      this.lastName = response.data.lastName;
    },
    async submitEdit() {
      let alert_msg = "";
      if (this.selectedEsu !== this.user.idEsu) {
        alert_msg += "<br />You are about to change the ESU for this user.";
      }

      if (this.emailDiff) {
        alert_msg += "<p><br />You are about to change the email from <strong>" + this.user.email + "</strong> to <strong>" + this.email + "</strong></p>";
      }

      if (this.roleHierarchyId !== this.user.roleHierarchyId) {
        alert_msg += "<p><br /> You are about to change the role for this user.</p>"
      }

      if (this.selectedDistrict !== this.user.idDistrict) {
        alert_msg += "<br />You are about to change the District for this user.";
      }

      if (this.selectedSchool !== this.user.idSchool && this.selectedSchool !== -1) {
        alert_msg += "<br />You are about to change the School for this user."
      }

      if (this.lastNameDiff) {
        alert_msg += "<p><br />You are about to change the last name from <strong>" + this.user.lastName + "</strong> to <strong>" + this.lastName + "</strong></p>";
      }

      if (this.firstNameDiff) {
        alert_msg += "<p><br />You are about to change the first name from <strong>" + this.user.firstName + "</strong> to <strong>" + this.firstName + "</strong></p>";
      }

      if (alert_msg !== "") {
        Swal.fire({
          title: "Confirm Changes to User",
          html: alert_msg,
          confirmButtonText: "I Confirm These Changes",
          cancelButtonText: "Cancel",
          passwordShowIcon: "warning",
          showCancelButton: true,
          allowEscapeKey: false
        }).then((did_submit) => {
          if (did_submit.isConfirmed) {
            Swal.fire({
              title: "Confirmed",
              text: "Submitted Changes to User",
              passwordShowIcon: "success",
              confirmButtonText: "OK"
            }).then((confirmed) => {
              if (confirmed) {
                this.postSubmit();
              }
            })
          } else if (did_submit.isDismissed) {
            Swal.DismissReason({
              title: "Canceled User Edit",
              text: "The user has not been updated"
            });
          }
        })
      }
    },
    async postSubmit() {
      let post_uri = API_URL + "/user/editUser";

      try {
        let request_body = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          idEsu: this.selectedEsu,
          idDistrict: this.selectedDistrict,
          idUser: this.user.idUser,
          idPosition: this.jobId,
          idSchool: this.selectedSchool,
          roleHierarchyId: this.roleHierarchyId
        };


        const response = await axios.post(
            post_uri,
            request_body,
            {
              headers: {
                "Authorization": "Bearer " + this.authenticated,
                "Content-Type": "application/json"
              }
            }
        )

        if (response.data === 200) {
          window.location.reload();
        }

      } catch (err) {
        console.error(err);
      }
    },
    setIsDisabled() {
      let id_pos = this.authUser.position.idPosition;
      let user_pos_id = this.user.idPosition;
      if (id_pos === 1) {
        this.isDisabled = false;
      } else if (id_pos === 5 && user_pos_id === 4) {
        this.isDisabled = false;
      } else if ((id_pos === 2 || id_pos === 3) && user_pos_id > id_pos) {
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
    changeSelectedCountry(value) {
      this.selectedCountry = value;
    },
    changeSelectedState(value) {
      this.selectedState = value;
    },
    changeSelectedEsu(value) {
      this.selectedEsu = value;
    },
    changeSelectedDistrict(value) {
      this.selectedDistrict = value;
    },
    updateEducationalOrganization(value) {
      this.educationalInstitution = value;
    },
    changeSchool(value) {
      this.selectedSchool = value;
    },
    async requestPasswordChange() {
      const request_uri =
          API_URL + "/user/requestPasswordChange?_email=" + this.user.email;

      try {
        let response = await axios.post(request_uri);
        if (response.status === 200) {
          Swal.fire({
            title: 'Password Reset Email Sent',
            text: 'User ' + this.user.email + ' will receive a password reset email shortly',
            passwordShowIcon: 'success'
          }).then((result) => {

          })
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  async created() {
    await this.getUser();
    this.setIsDisabled();
  },
};
</script>

<style scoped>
.card {
  position: relative;
  display: flex;
  margin: auto;
  margin-top: 50px;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  max-width: 550px;
  max-height: 650px;
  overflow-y: scroll;
}

input {
  color: #000;
}

.input-disabled {
  background-color: #e5e5e5;
}

.collapsible {
  background-color: aliceblue;
  color: slategrey;
  cursor: pointer;
  padding: auto;
  text-align: left;
  outline: 1px;
  font-size: 15px;
}

.active,
.collapsible:hover {
  background-color: #ccc;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: aliceblue;
}

span {
  color: red;
}
</style>
