import {createRouter, createWebHistory} from 'vue-router'
import Home from '../views/Home.vue'
import RegistrationForm from '../views/Login/RegistrationForm'
import Login from "../views/Login/Login"
import ParaAdminProfile from '../views/ParaAdminProfile/ParaAdminProfile'
import ParaEducatorEnrollment from '../views/ParaEducator/ParaEducatorEnrollment'
import ParaEducatorList from '../views/ParaEducator/ParaEducatorList'
import ParaAdminList from '../views/SuperUserViews/ParaAdminList'
import EmailRequest from '../views/PasswordChange/EmailRequest'
import PasswordChange from '../views/PasswordChange/PasswordChange'
import PasswordChangeLanding from '../views/RedirectLandings/PasswordChangeLanding'
import ForgotPassword from '../views/PasswordChange/UpdatePassword'
import SingleParaReport from '../views/ParaEducator/SingleParaReport'
import FAQ from '../views/FAQ/FAQ'
import Oauth from '../components/Oauth/Oauth'
import EditUser from "@/views/User/EditUser";
import RegistrationLanding from "@/views/RedirectLandings/RegistrationLanding"
import RequestHelp from "@/views/HelpPage/RequestHelp";
import store from "@/store";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {title: 'Project Para - Home'}
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
        meta: {title: 'Project Para - About'}
    },
    {
        path: "/privacyPolicy",
        name: 'PrivacyPolicy',
        component: () => import('@/views/PrivacyPolicy.vue'),
        meta: {title: 'Project Para - Privacy Policy'}
    },
    {
        path: "/tos",
        name: 'TermsOfService',
        component: () => import('@/views/TermsOfService.vue'),
        meta: {title: 'Project Para - Terms of Service'}
    },
    {
        path: '/oldRegister',
        name: 'OldRegistration',
        component: RegistrationForm,
        meta: {title: 'Project Para - Register'}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {title: 'Project Para - Sign In'}
    },
    {
        path: '/profile',
        name: 'AdminProfile',
        component: ParaAdminProfile,
        meta: {title: 'Project Para - My Profile', requiresAuth: true}
    },
    {
        path: '/oldAddUser',
        name: 'ParaEducatorEnrollment',
        component: ParaEducatorEnrollment,
        meta: {title: 'Project Para - Add User', requiresAuth: true}
    },
    {
        path: '/paraList',
        name: 'ParaEducatorList',
        component: ParaEducatorList,
        meta: {title: 'Project Para - My District Paras', requiresAuth: true}
    },
    {
        path: '/adminList',
        name: 'ParaAdminList',
        component: ParaAdminList,
        meta: {title: 'Project Para - Manage District Admins', requiresAuth: true}
    },
    {
        path: '/setPassword',
        name: 'PasswordChange',
        component: PasswordChange,
        meta: {title: 'Project Para - Change Password'}
    },
    {
        path: '/requestPasswordChange',
        name: 'EmailRequest',
        component: EmailRequest,
        meta: {title: 'Project Para - Email Request'}
    },
    {
        path: '/passwordChangeLanding',
        name: 'PasswordChangeLanding',
        component: PasswordChangeLanding,
        meta: {title: 'Project Para - Password Change Requested'}
    },
    {
        path: '/registrationConfirmed',
        name: 'RegistrationLanding',
        component: RegistrationLanding,
        meta: {title: 'Project Para - Registration Confirmed'}
    },
    {
        path: '/updatePassword',
        name: 'ForgotPassword',
        component: ForgotPassword,
        meta: {title: 'Project Para - Update Password'}

    },
    {
        path: '/individualReport/:idUser',
        name: 'SingleParaReport',
        component: SingleParaReport,
        meta: {title: 'Project Para - Individual Report', requiresAuth: true},
        props: true
    },
    {
        path: '/resources/v1',
        name: 'FAQV1',
        component: FAQ,
        meta: {title: 'Project Para - Resources'}
    },
    {
        path: "/resources",
        name: "FAQ",
        component: () => import("@/views/FAQ/ResourcesV2.vue"),
        meta: {title: "Project Para - Resources"}
    },
    {
        path: '/editUser/:idUser',
        name: 'EditUserOld',
        component: EditUser,
        meta: {title: 'Project Para - Edit User', requiresAuth: true},
        props: true
    },
    {
        path: '/viewUser/:idUser',
        name: 'ViewUser',
        component: () => import('@/views/User/ViewUser'),
        meta: {title: 'Project Para - View User'},
        props: true
    },
    {
        path: '/user/edit/:idUser',
        name: 'EditUser',
        component: () => import('@/views/Registration/EditUserComposition'),
        meta: {title: 'Project Para - Edit User', requiresAuth: true},
        props: true
    },
    {
        path: '/help/v1',
        name: 'RequestHelpV1',
        component: RequestHelp,
        meta: {title: 'Project Para - Help'}
    },
    {
        path: '/help',
        name: 'RequestHelp',
        component: () => import('@/views/HelpPage/RequestHelpV2.vue'),
        meta: {title: 'Project Para - Help'}
    },
    {
        path: '/register',
        name: 'Registration',
        component: () => import('@/views/Registration/SelfRegisterForm'),
        meta: {title: 'Project Para - Register'}
    },
    {
        path: '/addUser',
        name: 'AddUser',
        component: () => import('@/views/Registration/SuperUserRegistering'),
        meta: {title: 'Project Para - Add User', requiresAuth: true},
        props: true
    },
    {
        path: '/v1/userList',
        name: 'UserList',
        component: () => import('@/views/User/UserTableList'),
        meta: {title: 'Project Para - Users', requiresAuth: true}
    },
    {
        path: '/viewSchool/:idSchool',
        name: 'ViewSchool',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/School/SchoolView'),
        meta: {title: 'Project Para - View School'},
        props: true
    },
    {
        path: '/admin/createSchool',
        name: 'CreateSchool',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/School/SchoolCreate'),
        meta: {title: 'Project Para - Create School', requiresAuth: true},
    },
    {
        path: '/admin/editSchool/:idSchool',
        name: 'EditSchool',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/School/SchoolEdit'),
        meta: {title: 'Project Para - Edit School', requiresAuth: true},
        props: true
    },
    {
        path: '/viewDistrict/:idDistrict',
        name: 'ViewDistrict',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/District/DistrictView'),
        meta: {title: 'Project Para - View District'},
        props: true
    },
    {
        path: '/admin/editOrganization/:idOrganization',
        name: 'EditOutstate',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/OutstateOrganization/OutstateOrganizationEdit'),
        meta: {title: 'Project Para - Edit Outstate Organization', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/createOrganization',
        name: 'OutstateCreate',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/OutstateOrganization/OutstateOrganizationCreate'),
        meta: {title: 'Project Para - Create Outstate Organization', requiresAuth: true}
    },
    {
        path: '/view/organization/:idOrganization',
        name: 'ViewOutstate',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/OutstateOrganization/OustateOrganizationView'),
        meta: {title: 'Project Para - View Outstate Organization'},
        props: true
    },
    {
        path: '/admin/createDistrict',
        name: 'CreateDistrict',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/District/DistrictCreate'),
        meta: {title: 'Project Para - Create District', requiresAuth: true}
    },
    {
        path: '/admin/editDistrict/:idDistrict',
        name: 'EditDistrict',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/District/DistrictEdit'),
        meta: {title: 'Project Para - Edit District', requiresAuth: true},
        props: true
    },
    {
        path: '/viewEsu/:idEsu',
        name: 'ViewEsu',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/ESU/EsuView'),
        meta: {title: 'Project Para - View ESU'},
        props: true
    },
    {
        path: '/announcement/view/:announcementUuid',
        name: 'ViewAnnouncement',
        component: () => import('@/views/Announcements/ViewAnnouncement.vue'),
        meta: {title: 'Project Para - View Announcement'},
        props: true
    },
    {
        path: '/admin/editEsu/:idEsu',
        name: 'EditEsu',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/ESU/EsuEdit'),
        meta: {title: 'Project Para - Edit ESU', requiresAuth: true},
        props: true
    },
    {
        path: '/dashboard',
        name: 'AdminDashboard',
        component: () => import('@/views/SuperUserViews/AdminDashboard'),
        meta: {title: 'Project Para - Admin Dashboard', requiresAuth: true}
    },
    {
        path: '/admin/organizations/v1',
        name: 'OrganizationListV1',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/OrganizationTableList'),
        meta: {title: 'Project Para - Institution Management', requiresAuth: true}
    },
    {
        path: '/admin/organizations',
        name: 'OrganizationList',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/OrganizationTableV2.vue'),
        meta: {title: 'Project Para - Institution Management', requiresAuth: true}
    },
    {
        path: '/admin/paraReport/:idUser',
        name: 'ParaReport',
        component: () => import('@/views/SuperUserViews/Reporting/ParaCertifications/ParaCertifications'),
        props: true,
        meta: {title: 'Project Para - Para Certification', requiresAuth: true}
    },
    {
        path: '/admin/report/user/:idUser',
        name: 'CertificationReport',
        component: () => import('@/views/SuperUserViews/Reporting/ParaCertifications/CertificationsTemplate'),
        props: true,
        meta: {title: 'Project Para - Certifications', requiresAuth: true}
    },
    {
        path: '/admin/report/superuser',
        name: 'SuperUserReport',
        component: () => import('@/views/SuperUserViews/Reporting/Aggregate/AggregateSuperUserReport'),
        meta: {title: 'Project Para - Super User Report', requiresAuth: true}
    },
    {
        path: '/admin/esuReport/:idEsu',
        name: 'EsuReport',
        component: () => import('@/views/SuperUserViews/Reporting/Aggregate/AggregateEsuReport'),
        meta: {title: 'Project Para - ESU Report', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/assignmentManagement',
        name: 'AssignmentManagement',
        component: () => import('@/views/SuperUserViews/ESUCC/AssignmentManagement'),
        meta: {title: 'Project Para - Assignment Management', requiresAuth: true}
    },
    {
        path: '/admin/participantGrowth',
        name: 'ParticipantGrowth',
        component: () => import('@/views/SuperUserViews/Reporting/Aggregate/ParticipatingOrganizations'),
        meta: {title: 'Project Para - Participant Growth', requiresAuth: true}
    },
    {
        path: '/admin/participantGrowth/district/:idDistrict',
        name: 'DistrictParticipantGrowth',
        component: () => import('@/views/SuperUserViews/Reporting/ParticipantGrowth/DistrictParticipantGrowth'),
        meta: {title: 'Project Para - District Participation', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/participatingGrowth/all',
        name: 'AllParticipantGrowth',
        component: () => import('@/views/SuperUserViews/Reporting/ParticipantGrowth/AllParticipantGrowth'),
        meta: {title: 'Project Para - Site Participant Growth', requiresAuth: true}
    },
    {
        path: '/admin/participantGrowth/nebraska',
        name: 'NebraskaParticipantGrowth',
        component: () => import('@/views/SuperUserViews/Reporting/ParticipantGrowth/NebraskaParticipantGrowth'),
        meta: {title: 'Project Para - Nebraska Participant Growth', requiresAuth: true}
    },
    {
        path: '/admin/participantGrowth/site',
        name: 'SiteGrowth',
        component: () => import('@/views/SuperUserViews/Reporting/ParticipantGrowth/SiteParticipantGrowth'),
        meta: {title: 'Project Para - Site Participant Growth', requiresAuth: true}
    },
    {
        path: '/admin/quizzes/dashboard',
        name: 'QuizManagement',
        component: () => import('@/views/SuperUserViews/Reporting/Quizzes/QuizDashboard'),
        meta: {title: 'Project Para - Quiz Management', requiresAuth: true}
    },
    {
        path: '/admin/quizzes/report/:idQuiz',
        name: 'QuizReport',
        component: () => import('@/views/SuperUserViews/Reporting/Quizzes/QuizReport'),
        meta: {title: 'Project Para - Quiz Report', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/users/removed',
        name: 'RemovedUsers',
        component: () => import('@/views/User/RemovedUserList'),
        meta: {title: 'Project Para - Removed Users', requiresAuth: true}
    },
    {
        path: '/organization/v1/merge',
        name: 'OrganizationMergeV1',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/OutstateOrganization/OutstateOrganizationMergeDisplay'),
        meta: {title: 'Project Para - Merge Outstate Organizations', requiresAuth: true}
    },
    {
        path: '/organization/merge/:idEsu?/:idDistrict?/:idRemovingSchool?/:idRemovingDistrict?',
        name: 'OrganizationMerge',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/OrgMerge.vue'),
        meta: {title: 'Project Para - Merge Outstate Organizations', requiresAuth: true},
        props: true
    },
    {
        path: '/organization/hierarchy',
        name: 'OrganizationHierarchyAdminView',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/OrganizationHierarchyAdminView'),
        meta: {title: 'Project Para - Organization Hierarchy', requiresAuth: true}
    },
    {
        path: '/group/view/:idGroup',
        name: 'ViewGroup',
        component: () => import('@/views/SuperUserViews/UserGroups/UserGroupView'),
        meta: {title: 'Project Para - View Group', requiresAuth: true},
        props: true
    },
    {
        path: '/group/create',
        name: 'CreateGroup',
        component: () => import('@/views/SuperUserViews/UserGroups/UserGroupCreate'),
        meta: {title: 'Project Para - Create Group', requiresAuth: true}
    },
    {
        path: '/group/edit/:idGroup',
        name: 'EditGroup',
        component: () => import('@/views/SuperUserViews/UserGroups/UserGroupEdit'),
        meta: {title: 'Project Para - Edit Group', requiresAuth: true},
        props: true
    },
    {
        path: "/user/merge/:idKeepingUser/remove/:idRemovingUser",
        name: 'MergeUser',
        component: () => import('@/views/SuperUserViews/User/UserMergeDisplay'),
        meta: {title: 'Project Para - Merge Users', requiresAuth: true},
        props: true
    },
    {
        path: "/userList/V1",
        name: "UserSearchV1",
        component: () => import('@/views/User/UserSearch'),
        meta: {title: 'Project Para - Manage Users', requiresAuth: true},
    },
    {
        path: "/userList",
        name: "UserSearch",
        component: () => import('@/views/User/UserSearchV2'),
        meta: {title: 'Project Para - Manage Users', requiresAuth: true},
    },
    {
        path: '/user/mergeTable/:userBeingMergedId',
        name: 'MergeSearch',
        component: () => import('@/views/User/UserMergeSearchV2'),
        meta: {title: 'Project Para - User Merge Search', requiresAuth: true},
        props: true
    },
    {
        path: '/user/mergeTable/:userBeingMergedId',
        name: 'MergeSearchV1',
        component: () => import('@/views/User/UserMergeSearchTable'),
        meta: {title: 'Project Para - User Merge Search', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/reports/canvasreports',
        name: 'CanvasReports',
        component: () => import('@/views/SuperUserViews/Reporting/CanvasParaReports/CanvasReports'),
        meta: {title: 'Project Para - Canvas Reports', requiresAuth: true}
    },
    {
        path: '/admin/reports/dashboard',
        name: 'ReportDashboard',
        component: () => import('@/views/SuperUserViews/Reporting/ReportDashboard.vue'),
        meta: {title: 'Project Para - Report Dashboard', requiresAuth: true}
    },
    {
        path: '/admin/district/merge/:idKeepingDistrict/:idRemovingDistrict',
        name: 'MergeDistricts',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/District/DistrictMerge.vue'),
        meta: {title: 'Project Para - Merge Districts', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/district/merge/search/:idRemovingDistrict',
        name: 'DistrictMergeSearch',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/District/DistrictMergeSearch.vue'),
        meta: {title: 'Project Para - District Merge Search', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/school/merge/search/:idRemovingSchool',
        name: 'SchoolMergeSearch',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/School/SchoolMergeSearch.vue'),
        meta: {title: 'Project Para - School Merge Search', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/school/merge/:idKeepingSchool/:idRemovingSchool',
        name: 'MergeSchools',
        component: () => import('@/views/SuperUserViews/EducationalOrganizationManagement/School/SchoolMerge.vue'),
        meta: {title: 'Project Para - Merge Districts', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/announcement/create',
        name: 'CreateAnnouncement',
        component: () => import('@/views/SuperUserViews/Announcements/AnnouncementCreate.vue'),
        meta: {title: 'Project Para - Create Announcement', requiresAuth: true}
    },
    {
        path: '/admin/announcement/edit/:announcementUuid',
        name: 'EditAnnouncement',
        component: () => import('@/views/SuperUserViews/Announcements/AnnouncementEdit.vue'),
        meta: {title: 'Project Para - Edit Announcement', requiresAuth: true},
        props: true
    },
    {
        path: '/admin/announcement/dashboard',
        name: 'AnnouncementDashboard',
        component: () => import('@/views/SuperUserViews/Announcements/AnnouncementDashboard.vue'),
        meta: {title: 'Project Para - Announcement Dashboard', requiresAuth: true}
    },
    {
        path: '/componentExamples',
        name: 'ComponentExamples',
        component: () => import('@/components/ComponentExamples.vue'),
        meta: {title: 'Project Para - Component Examples'}
    },
    {
        path: "/badgrAuth",
        name: "BadgrAuth",
        component: () => import('@/views/SuperUserViews/Badgr/BadgrRedirectLanding.vue'),
        meta: {title: "Project Para - Badgr Redirect", requiresAuth: true}
    },
    {
        path: "/essay/review/:idEssayAnswer/grade/:idGrade",
        name: "EssayReview",
        component: () => import('@/views/SuperUserViews/Reporting/Essay/EssayReviewView.vue'),
        meta: {title: "Project Para - Review Essay", requiresAuth: true},
        props: true,
    },
    {
        path: "/quiz/review/:idQuiz/user/:idUser/grade/:idGrade",
        name: "UserQuizView",
        component: () => import('@/components/Quizzes/UserQuizView.vue'),
        meta: {title: "Project Para - Review Quiz", requiresAuth: true},
        props: true
    },
    {
        path: "/cert/historical",
        name: "HistoricalCerts",
        component: () => import('@/views/SuperUserViews/Historical/HistoricalCertSearch.vue'),
        meta: {title: "Project Para - Historical Certs", requiresAuth: true},

    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title

    let logged_in = store.dispatch('auth/isLoggedIn');

    if (to.meta.requiresAuth && store.getters['auth/user'] === null) {
        next({name: "Home"})
    }

    next()
})


export default router
