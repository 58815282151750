<template>
    <div>
        <label for="selectedSchool">{{
            (label !== null && label !== undefined && label !== "") ? label : `Select a Nebraska School`
            }}<span>*</span></label>
        <select class="form-select" v-model="selectedSchool" :id="selectedSchool" @change="changeSchool($event)"
                :disabled="schoolDisabled" style="height: 41px;" :tabindex="tabIdx">
            <option value="-1">Select a School</option>
            <option v-for="school in schools" :key="school.idSchool" :value="school.idSchool">{{
                school.schoolName
                }}
            </option>
        </select>
    </div>
</template>

<script>
import {API_URL} from "../../../Constants";
import {onBeforeMount, ref, watch} from "vue";
import axios from "axios";

export default {
	name: "SchoolSelectComposition",
	props: {
		idDistrict: Number,
		idSchool: Number,
		schoolDisabled: Boolean,
		changingDistrict: Boolean,
		label: String,
		tabIdx: {
			type: Number,
			required: false,
			default: 1,
		}
	},
	setup(props, context) {
		let selectedSchool = ref(-1);
		let schools = ref(null);

		watch(() => props.idDistrict, () => {
			getSchools();
			selectedSchool.value = -1
			context.emit("selectedSchool", selectedSchool.value);
		})

		watch(() => props.idSchool, () => {
			selectedSchool.value = props.idSchool;
		})

		onBeforeMount(() => {
			if (props.idDistrict !== -1) {
				getSchools();
				setDefaultVals();
			}
		})

		async function getSchools() {
			let get_uri = API_URL + "/school/byDistrict?_idDistrict=" + props.idDistrict;
			await axios.get(get_uri)
				.then((response) => {
					response.data.sort((a, b) => a.schoolName > b.schoolName ? 1 : -1);
					schools.value = response.data;
				})

		}

		function setDefaultVals() {
			if (!props.changingDistrict) {
				selectedSchool.value = props.idSchool;
			} else {
				selectedSchool.value = -1;
			}
		}

		function changeSchool(event) {
			selectedSchool.value = parseInt(event.target.value);
			context.emit("selectedSchool", selectedSchool.value);
		}

		return {
			schools,
			selectedSchool,
			changeSchool
		}
	}
}
</script>

<style scoped>
span {
    color: red;
}
</style>