import axios from 'axios';
import router from '../router';
import {API_URL, DAY_IN_MILLIS, MINUTE_IN_MILLIS} from '../../Constants.js';
import Swal from 'sweetalert2';

export default {
    namespaced: true,

    state: {
        access_token: localStorage.getItem('access_token'),
        user: JSON.parse(localStorage.getItem('user')),
        canvasState: localStorage.getItem('canvas_state'),
        refreshToken: localStorage.getItem('refresh_token'),
        timeout: localStorage.getItem('timeout'),
        refreshTimeout: localStorage.getItem('refresh_timeout')
    },

    getters: {
        authenticated(state) {
            return state.access_token;
        },
        user(state) {
            return state.user;
        },
        refresh(state) {
            return state.refreshToken;
        },
        timeout(state) {
            return state.timeout;
        },
        refreshTimeout(state) {
            return state.refreshTimeout;
        }
    },

    mutations: {
        SET_ACCESS_TOKEN(state, token) {
            state.access_token = token;
            localStorage.setItem('access_token', token);
            if (token === "null" || token === null) {
                localStorage.clear();
            }
        },
        SET_USER(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        SET_REFRESH_TOKEN(state, token) {
            state.refreshToken = token;
            localStorage.setItem('refresh_token', token);
        },
        SET_TIMEOUT(state, timeout) {
            state.timeout = timeout;
            localStorage.setItem('timeout', timeout);
        },
        SET_REFRESH_TIMEOUT(state, timeout) {
            state.refreshTimeout = timeout;
            localStorage.setItem('refresh_timeout', timeout);
        }
    },

    actions: {
        async signIn({dispatch}, content) {

            const post_data = JSON.stringify({
                email: content.email,
                password: content.password
            })

            const post_url = API_URL + "/auth/login";
            try {
                let response = await axios.post(post_url,
                    post_data,
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        params: {
                            'g-recaptcha-response': content.token
                        }
                    }
                );

                if (response.status === 400) {
                    Swal.fire({
                        title: 'Incorrect Credentials',
                        text: 'The password or email was incorrect, please try again.',
                        passwordShowIcon: 'question',
                        confirmButtonText: 'Retry Sign In'
                    })
                }


                if (response.data === "EXPIRED PASSWORD") {
                    Swal.fire({
                        title: "Password Expired",
                        text: "Your password has expired, click below to start a reset request",
                        confirmButtonText: "Okay"
                    }).then(() => {
                        window.open('/requestPasswordChange');
                    })


                }
                let access_body = {
                    token: response.data.access_token,
                    usedLoginPage: true
                }
                dispatch('attemptAccess', access_body);
                dispatch('attemptRefreshToken', response.data.refresh_token);
            } catch (err) {
                if (err.response.status === 404) {
                    Swal.fire({
                        title: 'Incorrect Credentials',
                        text: 'The password or email was incorrect, please try again.',
                        passwordShowIcon: 'question',
                        confirmButtonText: 'Retry Sign In'
                    })
                }
                if (err.response.status === 500) {
                    Swal.fire({
                        title: 'Incorrect Credentials',
                        text: 'The password or email was incorrect, please try again.',
                        passwordShowIcon: 'question',
                        confirmButtonText: 'Retry Sign In'
                    })
                }
            }
        },
        async refreshSignIn({dispatch}, content) {
            const post_data = JSON.stringify({
                email: content.email,
                refreshToken: content.refreshToken
            });

            const post_uri = API_URL + "/auth/refreshToken";

            try {
                let response = await axios.post(post_uri, post_data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                dispatch('attemptRefreshToken', response.data.refresh_token);
                let access_body = {
                    token: response.data.access_token,
                    usedLoginPage: false
                }
                dispatch('attemptAccess', access_body);
            } catch (err) {
                console.error(err);
            }

        },

        async attemptAccess({commit}, content) {
            commit('SET_ACCESS_TOKEN', content.token);
            let post_uri = API_URL + "/user/profile";
            try {
                let response = await axios.get(post_uri, {
                    headers: {
                        Authorization: 'Bearer ' + content.token
                    }
                });
                commit('SET_USER', response.data);
                let timeout = new Date(Date.now() + (MINUTE_IN_MILLIS));
                commit('SET_TIMEOUT', timeout.getTime());
                if (content.usedLoginPage) {
                    location.replace('/dashboard')
                }
            } catch (err) {
                console.error(err);
            }
        },
        checkTokenExpiration({dispatch}) {
            if (this.state.access_token) {
                let now_in_secs = Date.now() / 1000;
                let is_expiring = (this.state.timeout - now_in_secs) < 30;
                if (is_expiring) {
                    let content = {
                        email: this.state.user.contactEmail,
                        refreshToken: this.state.refresh_token
                    };
                    dispatch('REFRESH_SIGN_IN', content);
                }
            }
        },
        isLoggedIn({getters, dispatch}) {
            if (this.state.refresh_token) {
                if (getters.isLoggedIn) {
                    dispatch('checkTokenExpiration');
                }
                return getters.isLoggedIn;
            }
            return false;
        },
        async signOut({commit}) {
            commit('SET_USER', null);
            commit('SET_ACCESS_TOKEN', null);
            commit('SET_TIMEOUT', null);
            commit('SET_REFRESH_TIMEOUT', null)
            location.replace('/');
            // location.reload();
        },
        async attemptRefreshToken({commit}, token) {
            commit('SET_REFRESH_TOKEN', token);
            // let timeout = new Date(Date.now() + MINUTE_IN_MILLIS * 2);
            // commit('SET_REFRESH_TIMEOUT', timeout.getTime());
        },
        async attemptRefresh({commit}, token) {
            commit('SET_ACCESS_TOKEN', token);
        }
    }
}