<template>
  <nav class="navbar navbar-expand-lg navbar-light py-0">
    <div class="navbar-brand">
      <button class="button-logo">
        <img
            alt="Project Para Logo"
            src="../../assets/para_logo.png"
            style="height: 90%; width: 90%"
            @click="goHome"
        />
      </button>
      <button class="navbar-toggler bi bi-list btn" type="button" data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
              style="border: 1px solid black;">
        <span class=" navbar-toggler-icon"></span>
      </button>
    </div>
    <div class="navbar-collapse collapse" id="navbarSupportedContent">

      <router-link :to="{ name: 'Home' }">Home</router-link>
<!--      <router-link :to="{ name: 'About' }">About</router-link>-->
<!--      <router-link v-if="!getIsParaUser() && !getIsNdeUser() && user" :to="{ name: 'AddUser' }">Add User</router-link>-->
      <router-link :to="{ name: 'FAQ' }">Resources</router-link>
      <router-link v-if="getShowUserManagement()" :to="{ name: 'UserSearch' }">Manage Users</router-link>
      <router-link v-if="user" :to="{name: 'AdminDashboard'}">Dashboard</router-link>

      <router-link v-if="!user" :to="{ name: 'Registration' }" class="sign-in">Register</router-link>
      <router-link v-if="!user" :to="{ name: 'Login' }">Sign In</router-link>
      <div v-else class="dropdown" style="float: right;">
        <button class="dropbtn">
          <span style="color: black;">
            {{ getUserName() }}
            <br/>
            {{ getUserEmail() }}
            <i class="bi-caret-down-fill"></i></span>
          <div class="dropdown-content">
            <router-link :to="{name: 'ViewUser', params: {idUser: getEncryptedUserId()}}">My Profile</router-link>
            <a class=" dropdown-btn" @click="signOut">Sign Out</a>
          </div>
        </button>
      </div>
    </div>
  </nav>
</template>


<script>
// import API_URL from "../../../Constants.js";
// import axios from "axios";
import {mapGetters, mapActions} from "vuex";

export default {
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  data() {
    return {
      userPositionId: 4,
      showSuperUser: false,
      showParaManagement: false,
    };
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    signOutEvent() {
      localStorage.clear();
      this.signOut();
    },
    ...mapActions({
      signOut: "auth/signOut",
    }),
    getIsSuperUser() {
      if (this.user === null) return;

      return this.user.position.roleHierarchy === 1;
    },
    getIsEsuUser() {
      if (this.user === null) return;
      return this.user.position.roleHierarchy === 2;
    },
    getIsNdeUser() {
      if (this.user === null) return;
      return this.user.position.roleHierarchy === 6;
    },
    getIsDistrictUser() {
      if (this.user === null) return;

      return this.user.position.roleHierarchy === 3;
    },
    getIsParaUser() {
      if (this.user === null) return;
      return this.user.position.roleHierarchy === 5;
    },
    getIsInstructorUser() {
      if (this.user === null) return;
      return this.user.position.roleHierarchy === 4;
    },
    getShowUserManagement() {
      if (this.user == null) return;
      return this.user.position.roleHierarchy !== 5 && this.user.position.roleHierarchy !== 6;
    },
    getUserEmail() {
      if (this.user === null) return;
      return this.user.contactEmail;
    },
    getEncryptedUserId() {
      if (this.user === null) return;
      return btoa(this.user.idUser.toString());
    },
    getUserName() {
      if (this.user === null) return;
      return `${this.user.contactFirstName} ${this.user.contactLastName}`
    }
  },
};
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: inherit;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a, button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: inline-block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover, .dropdown-btn:hover {
  color: white;
  background: #0000ab;
}


/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
</style>
