<template>
  <form @submit.prevent="registerUser">
    <div class="card">
      <h1 style="margin-top: 50px">Add User</h1>
      <label for="firstName">First Name:</label>
      <input
          type="text"
          v-model="firstName"
          placeholder="First Name"
          required
      />
      <transition name="fade">
        <span v-if="msg.firstName">{{ msg.firstName }}</span>
      </transition>
      <label for="lastName">Last Name:</label>
      <input class="form-control" type="text" v-model="lastName" placeholder="Last Name" required/>
      <label for="jobTitle">Job Title:</label>
      <select class="form-select" v-model="jobTitle">
        <option value="-1" selected>Job Title</option>
        <option value="4">Paraeducator</option>
        <option value="5" v-if="user.position.idPosition === 1 || user.position.idPosition === 2">Supervisor</option>
        <option value="2" v-if="user.position.idPosition === 1">District Administrator</option>
        <option value="3" v-if="user.position.idPosition === 1">NDE</option>
        <option value="1" v-if="user.position.idPosition === 1">ESUCC/UNL</option>
      </select>
      <transition name="fade">
        <span v-if="msg.laststName">{{ msg.lastName }}</span>
      </transition>
      <label for="email">Email:</label>
      <input class="form-control" type="email" v-model="email" placeholder="Email" required/>
      <transition name="fade">
        <span v-if="msg.email">{{ msg.email }}</span>
      </transition>
      <label for="emailConfirm">Confirm Email:</label>
      <input
          type="email"
          v-model="emailConfirm"
          placeholder="Confirm Email"
          required
      />
      <transition name="fade">
        <span v-if="msg.emailConfirm">{{ msg.emailConfirm }}</span>
      </transition>
      <CountrySelect
          :idEsu="user.esu.idEsu !== null ? user.esu.idEsu : -1"
          :idSchool="user.school !== null ? user.school : -1"
          :idDistrict="user.district !== null ? user.district : -1"
          :esuDisabled="user.position.idPosition !== 1"
          :districtDisabled="user.position.idPosition !== 1"
          :showSchool="showSchool"
          :selfRegistering="false"
          :stateDisabled="user.position.idPosition !== 1"
          :countryDisabled="user.position.idPosition !== 1"
          @selectedCountry="changeSelectedCountry($event)"
          @selectedState="changeSelectedState($event)"
          @selectedEsu="changeSelectedEsu($event)"
          @selectedDistrict="changeSelectedDistrict($event)"
          @selectedSchool="changeSchool($event)"
      />
      <div v-if="!user.inNebraska">
        <label for="city">City:</label>
        <input class="form-control" type="text" v-model="city" placeholder="City" required/>
      </div>
      <div v-if="selectedState !== 'NE'">
        <OutNeInstitution
            :v-if="!inNebraska"
            @educationalInstitution="updateEducationalOrganization"
            v-bind:stateCode="selectedState"
            v-bind:countryCode="selectedCountry"
        />
      </div>

      <button type="submit" class="button" v-if="canSubmit">
        <!--@click=registerUser-->
        Register
      </button>
    </div>
  </form>

  <div>
    <router-link :to="{ name: 'Login' }"
    >Already have an account? Sign in here.
    </router-link
    >
  </div>
</template>

<script>
import axios from "axios";

import {API_URL} from "../../../Constants.js";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";

export default {
  components: {},
  data() {
    return {
      msg: [],
      email: "",
      emailConfirm: "",
      firstName: "",
      lastName: "",
      selectedState: "NE",
      states: [],
      selectedCountry: "",
      selectedEsu: null,
      selectedDistrict: null,
      selectedSchool: null,
      city: "",
      educationalInstitution: "",
      inNebraska: true,
      jobTitle: "-1",
    };
  },
  computed: {
    canSubmit() {

      let email_cleared = this.msg["email"] === "" &&
          this.msg["emailConfirm"] === "" && this.email !== "" && this.emailConfirm !== "";

      let name_cleared = this.msg["firstName"] === "" &&
          this.msg["lastName"] === "";

      let role_cleared = false;

      if (this.inNebraska) {
        if (this.jobTitle === "4" || this.jobTitle === "5") {
          role_cleared = parseInt(this.selectedSchool) !== -1;
        } else if (this.jobTitle === "2") {
          role_cleared = parseInt( this.selectedDistrict) !== -1;
        } else if (this.jobTitle === "3"){
          role_cleared = parseInt( this.selectedEsu) === 22;
        } else {
          role_cleared = parseInt(this.selectedEsu) !== -1;
        }
      }
      if (!this.inNebraska && this.jobTitle !== "-1") {
        role_cleared = this.educationalInstitution !== "";
      }
      return (email_cleared && name_cleared && role_cleared);
    },
    showSchool() {
      return this.jobTitle !== "3" && this.jobTitle !== "2" && this.jobTitle !== "-1";
    },
    ...mapGetters({
      user: "auth/user",
      authenticated: "auth/authenticated",
    }),
  },
  watch: {
    email(value) {
      this.email = value;
      this.validateEmail(value);
    },

    emailConfirm(value) {
      this.emailConfirm = value;
      this.validateConfirmEmail(value);
    },
    firstName(value) {
      this.firstName = value;
      this.validateFirstName(value);
    },
    lastName(value) {
      this.lastName = value;
      this.validateLastName(value);
    },
    jobTitle(value) {
      this.jobTitle = value;
    },
  },
  methods: {
    async validateEmail(value) {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
        let exists = await this.checkUserExists(value);

        if (exists === true) {
          this.msg["email"] =
              "A user with that email address is already registered!";
        } else {
          this.msg["email"] = "";
        }
      } else {
        this.msg["email"] = "Invalid Email Address Format";
      }
    },
    validateConfirmEmail(value) {
      if (value !== this.email) {
        this.msg["emailConfirm"] = "Email must match!";
      } else {
        this.msg["emailConfirm"] = "";
      }
    },
    validateFirstName(value) {
      if (value !== null || value !== "") {
        this.msg["firstName"] = "";
      } else {
        this.msg["firstName"] = "First Name is Required!";
      }
    },
    validateLastName(value) {
      if (value !== null || value !== "") {
        this.msg["lastName"] = "";
      } else {
        this.msg["lastName"] = "Last Name is Required!";
      }
    },
    async checkUserExists(value) {
      let post_url = API_URL + "/user/checkEmailExists";

      let exists = false;
      try {
        const response = await axios.get(post_url + "?_email=" + value);
        exists = response.data;
      } catch (err) {
        console.error(err);
      }
      return exists;
    },
    async registerUser() {
      if (!this.canSubmit) {
        return;
      }

      let post_url = API_URL + "/user/dmSetupUser";

      let keycloak_role_id = 4;

      if (this.jobTitle === "DistrictAdministrator") {
        keycloak_role_id = 2;
      } else if (this.jobTitle === "NDE") {
        keycloak_role_id = 3;
      } else if (this.jobTitle === "ESUCC") {
        keycloak_role_id = 1;
      }

      try {
        const response = await axios.post(
            post_url,
            {
              email: this.email,
              firstName: this.firstName,
              lastName: this.lastName,
              countryCode: this.selectedCountry,
              stateCode: this.selectedState,
              city: this.city,
              educationalInstitution: this.educationalInstitution,
              idPosition: parseInt(this.jobTitle),
              idEsu: parseInt(this.selectedEsu),
              idDistrict: parseInt(this.selectedDistrict),
              idSchool: parseInt(this.selectedSchool),
            },
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + this.authenticated
              },
            }
        );
      } catch (err) {
        console.error(err);
      }
      Swal.fire({
        title: "Successfully Registered",
        confirmButtonText: "Continue",
        passwordShowIcon: "success"
      }).then(function () {
        location.reload();
      })
    },
    changeSelectedCountry(value) {
      this.selectedCountry = value;
      this.inNebraska = this.selectedCountry === "US";
    },
    changeSelectedState(value) {
      this.selectedState = value;
      this.inNebraska = this.selectedState === "NE";
    },
    changeSelectedEsu(value) {
      this.selectedEsu = value;
    },
    changeSelectedDistrict(value) {
      this.selectedDistrict = value;
    },
    updateEducationalOrganization(value) {
      this.educationalInstitution = value;
    },
    changeSchool(value) {
      this.selectedSchool = value;
    },
    async getUser() {
      let get_uri = API_URL + "/user/byId?_idUser=" + parseInt(this.user.idUser);
      let response = await axios.get(get_uri, {
        headers: {
          "Authorization": "Bearer " + this.authenticated
        }
      });
      this.changeSelectedEsu(response.data.idEsu);
      this.changeSelectedDistrict( response.data.idDistrict);
      this.changeSchool(response.data.idSchool);
      this.changeSchool(response.data.idSchool);
      this.updateEducationalOrganization(response.data.outStateOrgName);
      this.changeSelectedCountry(response.data.countryCode);
      this.changeSelectedState(response.data.stateCode);
    },
  },
  async created() {
    await this.getUser()
  }
};
</script>

<style scoped>
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}

span {
  color: red;
  text-align: center;
  font-size: 100%;
}

.card {
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  text-align: center;
}
</style>