<template>
  <div class="navbar navbar-brand">
    <button class="button-logo">
      <img
          alt="Project Para Logo"
          src="../../assets/para_logo.png"
          @click="goHome"
          style="height: 80%; width: 80%"
      />
    </button>
    <div class="dropdown">
      <button
          class="btn btn-primary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
      >
        ...
      </button>

      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <router-link class="dropdown-item" :to="{ name: 'Home' }"
        >Home
        </router-link
        >
<!--        <router-link class="dropdown-item" :to="{ name: 'About' }"-->
<!--        >About-->
<!--        </router-link-->
<!--        >-->
        <router-link class="dropdown-item" :to="{name: 'FAQ'}">FAQ</router-link>
<!--        <router-link-->
<!--            class="dropdown-item"-->
<!--            v-if="getShowUserManagement"-->
<!--            :to="{ name: 'AddUser' }"-->
<!--        >Add User-->
<!--        </router-link-->
<!--        >-->
        <router-link v-if="user" :to="{ name: 'AdminProfile' }"
        >My Profile</router-link
        >

        <router-link
            class="dropdown-item"
            v-if="getIsSuperUser()"
            :to="{ name: 'UserList' }"
        >Manage Users
        </router-link
        >
        <router-link
            class="dropdown-item"
            v-if="!user"
            :to="{ name: 'Registration' }"
        >Register
        </router-link
        >

        <router-link v-if="!user" :to="{ name: 'Login' }">Sign In</router-link>
        <button class="btn-logout dropdown-item" @click="signOut">
          Sign Out
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  data() {
    return {
      isSuperUser: false,
    };
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    signOutEvent() {
      localStorage.clear();
      this.signOut();
    },
    ...mapActions({
      signOut: "auth/signOut",
    }),
    getIsSuperUser() {
      if (this.user === null) return;

      return this.user.position.roleHierarchyId === 1;
    },
    getIsEsuUser() {
      if (this.user === null) return;
      return this.user.position.roleHierarchyId === 2;
    },
    getIsNdeUser() {
      if (this.user === null) return;

      return this.user.position.roleHierarchyId === 6;
    },
    getIsDistrictUser() {
      if (this.user === null) return;

      return this.user.position.roleHierarchyId === 3;
    },
    getIsParaUser() {
      if (this.user === null) return;
      return this.user.position.roleHierarchyId === 5;
    },
    getIsInstructorUser() {
      if (this.user === null) return;
      return this.user.position.roleHierarchyId === 4;
    },
    getShowUserManagement() {
      if (this.user == null) return;
      return this.user.position.roleHierarchy !== 5;
    },
  },
  mounted() {
    // this.isLoggedIn();
  },
};
</script>


<style scoped>
.dropdown-toggle {
  background-color: #20949c;
}

.btn-primary {
  color: #c5c5cd;
}

.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: normal;
  align-items: normal;
  -ms-flex-pack: justify;
  justify-content: flex;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  background: rgb(112, 119, 216);
  margin-left: auto;
  margin-right: auto;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.dropdown-item {
  color: white;
  background: rgb(140, 169, 173);
  border-color: #2c3e50;
  border-radius: 10px;
  justify-content: center;
  align-self: right;
  margin-top: 3px;
  align-content: center;
}
</style>