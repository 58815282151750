<template>
  <br/>

  <div v-for="card in cardData" :key="card.id" class="album">
    <div class="card">
      <a :href="card.routeLink">
        <img
            class="card-img-top zoom"
            :src="card.imageUri"
            :alt="card.altDescription"
            style="margin: 25px; height: 190px; width: 300px;"
        />
      </a>
      <div class="card-body">
        <h5 class="card-title">
          <router-link :to="card.routeLink">
            {{ card.routerLabel }}
          </router-link>
        </h5>
        <div class="card-text" style="color: black;">{{ card.description }}</div>
      </div>
    </div>
    <br/>
  </div>
</template>

<script>
import classroom from "../assets/para_pics/classroom.jpeg";
import hallway from "../assets/para_pics/hallway.jpeg";
import orff_xylophone from "../assets/para_pics/orff_xylophone.jpeg";
import contact from "@/assets/para_pics/contact.png";
import getting_started from '@/assets/para_pics/getting_started.png'
import soon from "../assets/para_pics/soon.jpeg";
import faq from "../assets/para_pics/faq.jpeg";
import {API_URL, APP_URL} from "../../Constants";
import axios from "axios";
import {mapGetters} from "vuex";
import {isParaEducatorCollegeStudentOrEarlyChildhood} from "@/RoleCheck";
import EsuMultiSelect from "@/components/LocationSelect/EsuMultiSelect.vue";

export default {
  name: "Home",
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  data() {
    return {
      cardData: [
        {
          id: "0",
          imageUri: classroom,
          routeLink: "about",
          description: "Learn more about Project Para",
          altDescription:
              "Learn about Project Para.",
          routerLabel: "About Project Para",
        },
        {
          id: "1",
          imageUri: getting_started,
          routeLink: "resources",
          description: "Getting Started & Frequently Asked Questions",
          altDescription:
              "Getting Started & Frequently Asked Questions",
          routerLabel: "Frequently Asked Questions",
        },
        {
          id: "4",
          imageUri: contact,
          routeLink: "help",
          description: "Let's Start a Conversation",
          routerLabel: "Contact Us",
          altDescription: "Contact Us"
        }
      ],
      now: new Date(),
      announcementOneDate: new Date(2022, 11, 21, 9, 30)
    };
  },
  methods: {
    displaySignInCard() {
      let sign_in_card = {
        id: "3",
        imageUri: orff_xylophone,
        routeLink: "login",
        description: "Sign in to Project Para",
        altDescription:
            "Sign In",
        routerLabel: "Sign In",
      };
      let register_card = {
        id: "2",
        imageUri: hallway,
        routeLink: "register",
        description: "Register for a Project Para account.",
        altDescription: "Register an Account",
        routerLabel: "Register an Account",
      };
      if (!this.user) {
        this.cardData.push(register_card)
        this.cardData.push(sign_in_card);
      } else if (!isParaEducatorCollegeStudentOrEarlyChildhood(this.user.position.roleHierarchy)) {
        let add_user_card = {
          id: "2",
          imageUri: hallway,
          routeLink: "addUser",
          description: "Register for a Project Para account.",
          altDescription: "Register an Account",
          routerLabel: "Register an Account",
        };
        this.cardData.push(add_user_card)
      }
    },
    async canvasOauthGrant() {
      const canvas_code = location.search.slice(1).split("&")[0].split("=")[1];
      const redirect_state = location.search
          .slice(1)
          .split("&")[1]
          .split("=")[1];

      if (redirect_state === localStorage.getItem("canvas_state")) {
        try {
          localStorage.setItem("canvas_code", canvas_code);

          let post_uri = API_URL + "/canvas/auth/createToken";

          const response = await axios.post(
              post_uri,
              {
                grant_type: "authorization_code",
                code: canvas_code,
                redirect_uri: APP_URL,
              },
              {
                headers: {
                  Authorization: "Bearer " + this.authenticated,
                },
              }
          );
        } catch (err) {
          console.error(err);
        }
      } else {
        window.location.replace("/");
      }
    },
  },
  async mounted() {
    this.displaySignInCard();

    this.cardData.sort((a, b) => a.id > b.id ? 1 : -1)
    /**
     * Check if we're doing a canvas redirect, if so, do this.
     */
    if (window.location.href.indexOf("code") > -1) {
      this.canvasOauthGrant();
    }
  },
};
</script>

<style scoped>
.card {
  display: flex;
  max-width: 400px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  max-height: 800px;
  border-radius: 0;

  border: 1px solid rgb(0, 0, 0);

}

.card-img-top {
  height: 300px;
  width: 190px;
  margin: 5%;
  border-radius: 0;

  border: 1px solid rgb(0, 0, 0);
  overflow: hidden;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-text {
  margin-bottom: 0.5rem;
}

.album {
  display: inline-block;
}

.zoom {
  transition: 0.5s;
  width: 25%;
  height: 25%;
  margin: auto;
  overflow: hidden;
}

.zoom:hover {
  transform: scale(1.15);
  overflow: hidden;
}

</style>