<template>
  <form @submit.prevent="updatePassword">
    <div class="card" style="max-width: 400px; margin: auto; padding: 25px; border: 1px solid black; border-radius: 0">
      <label for="password">Password:</label>
      <div class="input-group mb-3">
        <input
            class="form-control"
            :type="passwordFieldType"
            v-model="password"
            placeholder="Password"
            spellcheck="false"
            required
        />
        <div class="input-group-append">
          <div class="input-group-append">
            <span class="input-group-text" @click="changePasswordFieldType" style="color: black;"><i><font-awesome-icon
                :icon="passwordShowIcon"/></i></span>

          </div>

        </div>

      </div>
      <span v-if="msg.passwordLength">{{ msg.passwordLength }}</span>
      <span v-if="msg.passwordContains">{{ msg.passwordContains }}</span>
      <span v-if="containsLowerCase === false">At least one lowercase letter.</span>
      <span v-if="containsUpperCase === false">At least one uppercase letter.</span>
      <span v-if="containsNumber === false">At least one number.</span>
      <span v-if="containsSpecialCharacter === false">At least one special character (#?!@$%^&*-).</span>
      <br/>
      <label for="passwordConfirm">Confirm Password:</label>
      <div class="input-group mb-3">
        <input
            class="form-control"
            :type="passwordFieldType"
            v-model="passwordConfirm"
            placeholder="Confirm Password"
            spellcheck="false"
            required
        />
        <div class="input-group-append">

          <span class="input-group-text" @click="changePasswordFieldType" style="color: black;"><i>
            <font-awesome-icon :icon="passwordShowIcon"/></i></span>

        </div>
      </div>
      <br/>
      <span v-if="msg.passwordConfirm">{{ msg.passwordConfirm }}</span>
      <button type="submit" class="button btn-primary" :disabled="!canSubmit()">Update Password</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import {API_URL} from "../../../Constants.js";
import Swal from "sweetalert2";
import {faEye, faEyeSlash} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

export default {
  // components: {
  //   FontAwesomeIcon
  // },
  data() {
    return {
      msg: [],
      password: "",
      passwordConfirm: "",
      previousPath: null,
      email: null,
      timed_out: false,
      containsUpperCase: null,
      containsLowerCase: null,
      containsNumber: null,
      containsSpecialCharacter: null,
      passwordFieldType: 'password',
      passwordShowIcon: faEye
    };
  },
  watch: {
    password(value) {
      this.password = value;
      this.validatePassword(value);
    },
    passwordConfirm(value) {
      this.passwordConfirm = value;
      this.validatePasswordConfirm(value);
    },
  },
  methods: {
    validatePassword(value) {
      let diff = 8 - value.length;
      if (value.length < 8) {
        this.msg["passwordLength"] =
            "Must be 8 characters " + diff + " characters remaining";
      } else {
        this.msg["passwordLength"] = "";
        this.msg["passwordContains"] = "Password must contain:";
        this.containsUpperCase = /[A-Z]/.test(value);
        this.containsLowerCase = /[a-z]/.test(value);
        this.containsNumber = /[0-9]/.test(value);
        this.containsSpecialCharacter = /[#?!@$%^&*-]/.test(value);
      }

      if (this.containsUpperCase
          && this.containsLowerCase
          && this.containsNumber
          && this.containsSpecialCharacter) {
        this.msg["passwordContains"] = "";
      }
    },
    validatePasswordConfirm(value) {
      if (value !== this.password) {
        this.msg["passwordConfirm"] = "Passwords must match!";
      } else {
        this.msg["passwordConfirm"] = "";
      }
    },
    canSubmit() {
      return this.msg["passwordLength"] === ""
          && this.msg["passwordConfirm"] === ""
          && this.password !== ""
          && this.containsSpecialCharacter
          && this.containsUpperCase
          && this.containsLowerCase
          && this.containsNumber;
    },
    changePasswordFieldType() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.passwordShowIcon = this.passwordFieldType === "password" ? faEyeSlash : faEye;
    },
    async updatePassword() {
      const post_uri = API_URL + "/user/updatePassword";

      const post_data = JSON.stringify({
        email: this.email,
        password: this.password,
      });
      await axios.put(post_uri, post_data, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((result) => {
        if (result.status === 200) {
          this.$router.push({name: "Login"});
        } else if (result.status === 400) {
          Swal.fire({
            title: 'Reusing Old Password?',
            text: 'You cannot reuse your previous 5 passwords.',
            icon: 'error',
            confirmButtonText: 'Retry Changing Password'
          })
        }
        if (result.status === 400) {
          Swal.fire({
            title: 'Reusing Old Password?',
            text: 'You cannot reuse your previous 5 passwords.',
            icon: 'error',
            confirmButtonText: 'Retry Changing Password'
          })
        }
        if (result.status === 400) {
          Swal.fire({
            title: 'Reusing Old Password?',
            text: 'You cannot reuse your previous 5 passwords.',
            icon: 'error',
            confirmButtonText: 'Retry Changing Password'
          })
        }
        if (result.status === 400) {
          Swal.fire({
            title: 'Reusing Old Password?',
            text: 'You cannot reuse your previous 5 passwords.',
            icon: 'error',
            confirmButtonText: 'Retry Changing Password'
          })
        }
      }).catch(({message}) => {
        // console.log(message);
        if (message.includes('400')) {
          Swal.fire({
            title: 'Reusing Old Password?',
            text: 'You cannot reuse your previous 5 passwords.',
            icon: 'error',
            confirmButtonText: 'Retry Changing Password'
          })
        } else if (message.includes('500')) {
          Swal.fire({
            title: 'Reusing Old Password?',
            text: 'You cannot reuse your previous 5 passwords!',
            icon: 'error',
            confirmButtonText: 'Retry Changing Password'
          })
        }
      })

      // try {
      //   let response = await axios.put(post_uri, post_data, {
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //   });
      //   console.log(response);
      //   console.log(response.status);
      //   if (response.status === 400) {
      //     Swal.fire({
      //       title: 'Reusing Old Password?',
      //       text: 'You cannot reuse your previous 5 passwords!',
      //       icon: 'error',
      //       confirmButtonText: 'Retry Changing Password'
      //     })
      //   }
      //   if (response.status === 200) {
      //     await this.$router.push({name: "Login"});
      //   }
      //
      // } catch (err) {
      //   Swal.fire({
      //     title: 'Something Went Wrong',
      //     text: 'Make sure the passwords match!',
      //     icon: 'error',
      //     confirmButtonText: 'Retry Changing Password'
      //   })
      // }
    },
    isValidRedirect() {
      const {srcTime, src} = this.$route.query;
      if (!srcTime || !src) return false;
      const decode_timestamp = atob(srcTime);
      const decode_src = atob(src);
      // if (decode_src !==  APP_URL + "/#/setPassword") return false;

      let time_limit = 60 * 60 * 24; // 24 hours in seconds, ignoring millis for time out
      let expired_time = parseInt(decode_timestamp, 10) + time_limit;

      let now_time = new Date();
      let now = Date.parse(now_time) / 1000;

      if (now > expired_time) {
        this.timed_out = true;
      }
      this.email = decode_src;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.previousPath = from.path;
    });
  },
  mounted() {
    // TO DO: Redirect to home if not valid.
    this.isValidRedirect();

    if (this.timed_out) {
      alert("The Password Change Request has timed out");
      this.$router.push({name: "EmailRequest"});
    }
  },
};
</script>

<style scoped>
span {
  color: red;
  text-align: center;
  font-size: 100%;
}

</style>