<template>
  <label>What do you need help with?<span style="color: red;">*</span></label>
  <select class="form-select" v-model="selectedHelpType" :id="selectedHelpType" @change="updateSelectedType($event)" :style="selectStyle">
    <option value="-1">What can we help with?</option>
    <option v-for="helpType in helpTypes" :key="helpType.idHelpType" :value="helpType.idHelpType">{{helpType.typeDescription}}</option>
  </select>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {API_URL} from "../../../Constants";
import axios from "axios";

export default {
  name: "HelpTypeDropdown",
  emits: ["idHelpType"],
  props: {
    selectStyle: String
  },
  setup(props, context){
    let helpTypes = ref(null);
    let selectedHelpType = (-1);

    onBeforeMount(() => {
      getHelpTypes();
    })

    async function getHelpTypes(){
      let get_uri = API_URL + "/helpType/all";
      await axios.get(get_uri)
      .then((result) => {
        helpTypes.value = result.data;
      })
    }

    function updateSelectedType(event){
      selectedHelpType = event.target.value;
      context.emit("idHelpType", event.target.value);
    }

    return {
      helpTypes,
      selectedHelpType,
      updateSelectedType
    }
  }
}
</script>

<style scoped>

</style>