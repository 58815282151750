<template>
  <label for="esuSelect">Select a Nebraska ESU:</label>
  <select class="form-select" v-model="selectedEsu" v-bind="changeEsu()" :disabled="esuDisabled !== undefined ? esuDisabled : false">
    <option value="-1" selected>Select an ESU</option>
    <option v-for="esu in esus" v-bind:value="esu.idEsu" :key="esu.idEsu">
      {{ esu.esuName }}
    </option>
  </select>
  <div
      v-show="parseInt(selectedEsu) !== -1 && parseInt(selectedEsu) !== 21 && parseInt(selectedEsu) !== 22 &&  parseInt(selectedEsu) !== 20">
    <DistrictSelect
        :idEsu="selectedEsu"
        :idDistrict="selectedDistrict"
        :idSchool="selectedSchool"
        :distDisabled="distDisabled"
        :showSchool="showSchool"
        :schoolDisabled="schoolDisabled"
        @selectedDistrict="changeDistrict($event)"
        @selectedSchool="changeSchool($event)"
    />
  </div>
</template>

<script>
import axios from "axios";

import {API_URL} from "../../../Constants.js";

export default {
  props: ["idEsu", "idDistrict", "idSchool", "esuDisabled", "distDisabled", "schoolDisabled", "selfRegistering", "showSchool"],
  emits: ["selectedEsu", "selectedDistrict", "selectedSchool"],
  components: {},
  data() {
    return {
      esus: [],
      selectedEsu: "-1",
      selectedDistrict: 0,
      selectedSchool: 0,
      isRegistering: this.selfRegistering,
      jobTitle: -1
    };
  },
  computed: {},
  watch: {
    selectedEsu(value) {
      this.selectedEsu = value;
      this.changeEsu();
    },
    selectedSchool(value) {
      this.selectedSchool = value;
    },
    jobId: function (value) {
      this.props.jobId = value;
    },
    idSchool(value) {
      this.selectedSchool = value;
    }
  },
  methods: {
    async getEsus() {
      let self_registering = btoa('true');
      if (!this.selfRegistering) self_registering = btoa('false');
      const response = await axios.get(API_URL + "/esu/all?_isDisabled=" + self_registering);
      this.esus = response.data;
    },
    changeEsu() {
      this.$emit("selectedEsu", this.selectedEsu);
    },
    changeDistrict(value) {
      this.selectedDistrict = value;
      this.$emit("selectedDistrict", this.selectedDistrict);
    },
    changeSchool(event) {
      this.selectedSchool = event;
      this.$emit("selectedSchool", this.selectedSchool);
    },
  },
  created() {
    this.getEsus();
    if (this.idEsu !== this.selectedEsu) {
      this.selectedEsu = this.idEsu;
    } else {
      this.selectedEsu = '-1';
    }
    if (this.idDistrict !== this.selectedDistrict) {
      this.selectedDistrict = this.idDistrict;
    } else {
      this.selectedDistrict = '-1';
    }
    if (this.idSchool !== this.selectedSchool) {
      this.selectedSchool = this.idSchool;
    } else {
      this.selectedSchool = '-1';
    }

    if (this.esuDisabled !== null) {
      this.isDisabled = this.esuDisabled;
    }

  },
};
</script>

<style>
select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 0);
  color: #555;
  border-radius: 10px;
}
</style>