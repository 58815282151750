<template>
  <h1 style="color: black;"> Contact Us</h1>
  <h3 style="color: black;"> Once an email is received, please allow 1 to 2 business days for us to respond.</h3>
  <div class="row">
    <div class="col-sm" style="margin-right: 50px; margin-top: 50px;">
      <h4 style="color: black;"> Questions?</h4>
      <h5 style="margin-top: 33px; color: black;">We want to know what you have to say and we look forward to hearing from you!</h5>
      <br/>
      <h5 style="color: black;"> We've listed some emails below for your convenience, or feel free to send us a
        note!</h5>

      <h6 style="margin-top: 50px; color: black">Canvas Help</h6>
      <h6 style="color: black;"> 
        <a href="mailto:paracanvas@myesu.org">paracanvas@myesu.org</a>
      </h6>
      <hr/>
      <h6 style="color: black;"> Registration Help</h6>
      <h6 style="color: black;"> 
        <a href="mailto:pararegistration@myesu.org">pararegistration@myesu.org</a>
      </h6>
      <hr/>
      <h6 style="color: black;"> General Questions</h6>
      <h6 style="color: black;"> 
        <a href="mailto:parahelp@myesu.org">parahelp@myesu.org</a>
      </h6>
    </div>
    <div class="col-sm" style="margin-left: 50px; margin-top: 50px;">
      <h4 style="color: black;"> Common Help Requests</h4>
      <form @submit.prevent="recaptcha">
        <div>
          <HelpTypeDropdown @idHelpType="updateIdHelpType($event)" :select-style="'width: 300px;'"/>
        </div>

        <div>
          <label for="emailRequesting">Email Address<span>*</span></label>
          <input
                  class="form-control"
              type="email"
              v-model="emailRequesting"
              placeholder="Email Address"
              id="emailRequesting"
              @change="changeEmailRequesting($event)"
              required
                  style="height: 41px"

          />
        </div>
        <div>
          <label for="nameRequesting">Name Requesting<span>*</span></label>
          <input
                  class="form-control"
              type="text"
              v-model="nameRequesting"
              placeholder="First and Last Name"
              id="nameRequesting"
              @change="changeNameRequesting($event)"
              required
                  style="height: 41px"
          />
        </div>
        <div>
          <label for="helpDescription">Details<span>*</span></label>
          <br/>
          <textarea
                  class="form-control"
              type="textarea"
              style="height: auto; background-color: white"
              v-model="helpDescription"
              placeholder="Please describe the issue you are having as best you can."
              id="helpDescription"
              @change="changeHelpDescription($event)"
              required
          />
        </div>
        <div>
          <button type="submit" class="button btn-primary" :disabled="!canSubmit">Submit Help Request</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import HelpTypeDropdown from "@/components/HelpType/HelpTypeDropdown";
import {computed, ref} from "vue";
import {API_URL} from "../../../Constants";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
import {useReCaptcha} from "vue-recaptcha-v3";
import {sanitizeNonPasswordInput} from "@/SanitizeInput";
import isEmail from "validator/es/lib/isEmail";

export default {
  name: "RequestHelp",
  components: {HelpTypeDropdown},
  setup() {
    let nameRequesting = ref(null);
    let helpDescription = ref(null);
    let emailRequesting = ref(null);
    let idHelpType = ref(-1);
    let isValidEmail = ref(false);
    const {executeRecaptcha, recaptchaLoaded} = useReCaptcha();

    const canSubmit = computed(() => {
      return validSubmit();
    });

    async function submitHelpRequest(recaptchaToken) {
      let post_uri = API_URL + "/helpRequest/sendRequest";
      let post_body = {
        nameRequesting: nameRequesting.value,
        helpDescription: helpDescription.value,
        emailRequesting: emailRequesting.value,
        idHelpType: parseInt(idHelpType.value),
      };
      await axios
          .post(post_uri, post_body, {
            headers: {
              "Content-Type": "application/json",
            },
            params: {
              'g-recaptcha-response': recaptchaToken
            }
          })
          .then((result) => {
            Swal.fire({
              title: "Help Request Sent",
              passwordShowIcon: "info",
              text: result.data,
            }).then((confirmClick) => {
              if (confirmClick) router.push({name: "Home"});
            });
          });
    }

    function changeNameRequesting(event) {
      nameRequesting.value = sanitizeNonPasswordInput(event.target.value);
      validSubmit();
    }

    function changeEmailRequesting(event) {
      emailRequesting.value = sanitizeNonPasswordInput(event.target.value);
      validateEmail(event.target.value);
    }

    function changeHelpDescription(event) {
      helpDescription.value = sanitizeNonPasswordInput(event.target.value);
      validSubmit();
    }

    function updateIdHelpType(event) {
      idHelpType.value = parseInt(event);
      validSubmit();
    }

    function validSubmit() {
      return (
          isValidEmail.value &&
          idHelpType.value !== -1 &&
          emailRequesting.value !== null &&
          emailRequesting.value !== "" &&
          nameRequesting.value !== null &&
          nameRequesting.value !== "" &&
          helpDescription.value !== null &&
          helpDescription.value !== ""
      );
    }

    function validateEmail(value) {
      isValidEmail.value = isEmail(value);
      validSubmit();
    }

    async function recaptcha() {
      await recaptchaLoaded();
      const token = await executeRecaptcha('login');
      await submitHelpRequest(token)
    }

    return {
      submitHelpRequest,
      helpDescription,
      idHelpType,
      nameRequesting,
      emailRequesting,
      updateIdHelpType,
      changeNameRequesting,
      changeHelpDescription,
      changeEmailRequesting,
      recaptcha,
      canSubmit,
    };
  },
};
</script>

<style scoped>
h6 {
  text-align: left;
}

textarea {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  background-color: #f8f8f8;
  font-size: 16px;
  resize: none;
}

hr {
  border: 1px solid black;
  background-color: #000000;
  /*opacity: 1;*/
  width: 300px;
}

form {
  text-align: left;
}

span {
  color: red;
}

h5 {
  text-align: left;

}

</style>