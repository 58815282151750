<template>
  <label v-if="labelText">{{ labelText }}</label>
  <label class="switch" style="margin: auto">
    <input type="checkbox" :checked="checked" @change="updateChecked()"/>
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  props: ["isActive", "labelText"],
  emits: ["checked"],
  data() {
    return {
      checked: this.isActive,
    };
  },
  methods: {
    updateChecked() {
      this.$emit("checked", this.checked);
    },
  },
  mounted() {
    this.checked = this.isActive;
  },
};
</script>
<style scoped>
.switch {
  position: relative;
  display: flex;
  width: 50px;
  height: 25px;
}

.switch input {
  opacity: 0;
  height: 0;

}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 15px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #a4d541;
}

input:focus + .slider {
  box-shadow: 0 0 1px greenyellow;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round:before {
  border-radius: 50%;
}
</style>