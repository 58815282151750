<template>
  <form @submit.prevent="requestEmailChange">
    <div class="card">
      <label for="email">Email:</label>
      <input class="form-control" type="email"
        v-model="email"
        placeholder="Email"
        required>
        <br>
        <button type="submit" class="button btn-primary">Request Password Change</button>
    </div>
  </form>
</template>

<script>
import {API_URL} from '../../../Constants.js'
import axios from 'axios'
import Swal from "sweetalert2";

export default{
  data(){
    return {
      email: "",
    }
  },
  watch: {
    email(value){
      this.email = value;
    }
  },
  methods: {
    async requestEmailChange(){
      const request_uri = API_URL + "/user/requestPasswordChange?_email=" + this.email;

      try{
        let response = await axios.post(request_uri);
        if (response.status === 200){

          if (response.data === "Pending"){
            Swal.fire({
              title: 'Account Pending Approval',
              text: 'This account is pending approval.  ' +
                  'Please allow 1-2 business days for a Project Para team member to review your request. ' +
                  'Once authorized, you will receive an email with further instructions.',
              icon: 'info',
              confirmButtonText: 'Go Home'
            }).then((result) => {
              if (result) this.$router.push({name: 'Home'});
            })
          }else {
            Swal.fire({
              title: 'Password Change Request Sent',
              text: 'If a matching account was found, an email was sent to ' + this.email + ' to allow you to reset your password.',
              icon: 'info',
              confirmButtonText: 'Go Home'
            }).then((result) =>{
              if (result) this.$router.push({name: 'Home'});
            });
          }
        }else{
          alert(response.data);
        }
      }catch(err){
        console.error(err);
      }
    }
  }
}
</script>

<style scoped>
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}

span {
  color: red;
  text-align: center;
  font-size: 100%;
}

.card {
  position: relative;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  margin: auto;
  padding: 25px;
}
</style>