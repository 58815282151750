<template>
  <label for="selectedCountry">Country: </label>
  <select class="form-select" v-model="selectedCountry" v-bind="countryChanged()" :disabled="countryDisabled">
    <option value="-1" selected>Select a country</option>
    <option
        v-for="country in countries"
        v-bind:value="country.countryCode"
        :key="country.idCountry"
    >
      {{ country.countryName }}
    </option>
  </select>
  <transition name="fade">
    <div v-show="selectedCountry === 'US'">
      <StateSelect
          id="stateSelect"
          ref="stateSelect"
          :selfRegistring="isRegistering"
          :showSchool="showSchool"
          :idEsu="selectedEsu"
          :idDistrict="selectedDistrict"
          :idSchool="selectedSchool"
          :esuDisabled="esuDisabled"
          :districtDisabled="districtDisabled"
          :schoolDisabled="schoolDisabled"
          :stateDisabled="stateDisabled"
          @selectedState="selectedStateChanged($event)"
          @selectedDistrict="changeDistrict($event)"
          @selectedEsu="changeSelectedEsu($event)"
          @selectedSchool="changeSchool($event)"
          @updateOutStateOrg="updateOutStateOrg($event)"
      />
    </div>
  </transition>
</template>

<script>
import axios from "axios";
import {API_URL} from "../../../Constants.js";

export default {
  props: ["idEsu", "idDistrict", "idState", "idSchool", "selfRegistering", "showSchool", "esuDisabled", "districtDisabled", "countryDisabled", "schoolDisabled", "stateDisabled"],
  emits: ["selectedCountry", "selectedState", "selectedDistrict", "selectedEsu", "selectedSchool"],
  data() {
    return {
      countries: [],
      selectedCountry: "US",
      educationalInstitution: "",
      stateSelect: "NE ",
      selectedEsu: this.idEsu,
      selectedDistrict: this.idDistrict,
      selectedSchool: this.idSchool,
      isRegistering: this.selfRegistering,
    };
  },
  watch: {
    idEsu(value){
      this.changeSelectedEsu(value);
    },
    idSchool(value){
      this.changeSchool(value);
    },
    idDistrict(value){
      this.changeDistrict(value);
    },
    idState(value){
      this.selectedStateChanged(value);
    }
  },
  methods: {
    async getcountries() {
      const response = await axios.get(API_URL + "/countries/all");
      this.countries = response.data;
    },
    countryChanged() {
      this.$emit("selectedCountry", this.selectedCountry);
    },
    selectedStateChanged(value) {
      this.stateSelect = value;
      this.$emit("selectedState", this.stateSelect);
    },
    changeSelectedEsu(value) {
      this.selectedEsu = value;
      this.$emit("selectedEsu", this.selectedEsu);
    },
    changeDistrict(value) {
      this.selectedDistrict = value;
      this.$emit("selectedDistrict", this.selectedDistrict);
    },
    changeSchool(value) {
      this.selectedSchool = value;
      this.$emit("selectedSchool", this.selectedSchool);
    },
  },
  async mounted() {
    await this.getcountries();
  },
};
</script>

<style scoped>
select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 0);
  color: #555;
  border-radius: 10px;
  background-color: #ffff;
}
</style>