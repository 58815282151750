<template>
    <label for="selectedDistrict">Select a Nebraska District<span>*</span></label>
    <select class="form-select" :tabindex="tabIdx" v-model="selectedDistrict" :id="selectedDistrict"
            @change="changeDistrict($event)"
            :disabled="districtDisabled" style="height: 41px;">
        <option value="-1">Select a District</option>
        <option v-for="district in districts" :key="district.idDistrict" :value="district.idDistrict">
            {{ district.districtName }}
        </option>
    </select>
    <div v-if="selectedDistrict !== -1 && showSchool">
        <SchoolSelectComposition
                :idDistrict="selectedDistrict"
                ref="idSchool"
                @selectedSchool="updateSchool($event)"
                :schoolDisabled="disabledSchool"
                :idSchool="selectedSchool"
                :changingDistrict="changingDistrict"
                :tab-idx="tabIdx + 1"
        />
    </div>
</template>

<script>
import axios from 'axios';
import {computed, onBeforeMount, ref, watch} from "vue";
import SchoolSelectComposition from "@/components/LocationSelect/SchoolSelectComposition";
import {API_URL} from "../../../Constants";

export default {
	name: "DistrictSelectComposition",
	components: {SchoolSelectComposition},
	props: {
		idEsu: Number,
		idDistrict: Number,
		idSchool: Number,
		schoolDisabled: Boolean,
		districtDisabled: Boolean,
		showSchool: Boolean,
		changingEsu: Boolean,
		tabIdx: {
			type: Number,
			required: false,
		}
	},
	// emits: ["selectedSchool", "selectedDistrict"],
	setup(props, context) {
		let selectedDistrict = ref(-1);
		let districts = ref(null);
		let selectedSchool = ref(-1);
		let disabledSchool = ref(props.schoolDisabled);

		watch(() => props.idEsu, () => {
			getDistricts();
			if (props.changingEsu) {
				selectedDistrict.value = -1;
				context.emit("selectedDistrict", selectedDistrict.value)
				selectedSchool.value = -1;
				context.emit("selectedSchool", selectedSchool.value);
			}
		})

		watch(() => props.idDistrict, () => {
			selectedDistrict.value = props.idDistrict;
			selectedSchool.value = -1;
		})

		watch(() => props.idSchool, () => {
			selectedSchool.value = -1;
		})

		onBeforeMount(() => {
			if (props.idEsu !== -1) {
				getDistricts();
				setDefaultVals();
			}
		})

		const changingDistrict = computed(() => {
			return props.idDistrict !== selectedDistrict.value;
		})


		async function getDistricts() {
			let get_uri = API_URL + "/districts/byEsu?_idEsu=" + props.idEsu;
			await axios.get(get_uri)
				.then((response) => {
					response.data.sort((a, b) => a.districtName > b.districtName ? 1 : -1);
					districts.value = response.data;
					if (districts.value.some(d => d.idDistrict === props.idDistrict)) {
						selectedDistrict.value = props.idDistrict;
					}
				})
		}

		function setDefaultVals() {
			if (!props.changingEsu) {
				selectedDistrict.value = props.idDistrict;
				selectedSchool.value = props.idSchool;
			} else {
				selectedDistrict.value = -1;
				selectedSchool.value = -1;
			}

			// if (props.idSchool !== undefined && props.idSchool !== -1) {
			//   selectedSchool.value = props.idSchool;
			// } else {
			//   selectedSchool.value = -1;
			// }

		}

		function changeDistrict(event) {
			selectedDistrict.value = parseInt(event.target.value);
			context.emit("selectedDistrict", selectedDistrict.value);
		}

		function updateSchool(event) {
			selectedSchool.value = parseInt(event);
			context.emit("selectedSchool", event);
		}

		return {
			districts,
			selectedDistrict,
			selectedSchool,
			disabledSchool,
			updateSchool,
			changeDistrict,
			changingDistrict
		}
	}
}
</script>

<style scoped>
span {
    color: red;
}
</style>