export const API_URL = "https://para-staging-api.myesu.org"
export const APP_URL = "https://para-staging.myesu.org"

export const ZIP_CODE_REGEX = /^\d{0,5}$/;
export const NUM_REGEX = /^\d/;
export const CDN_REGEX = /^\d{2}[-]\d{4}[-]\d{3}/;

export const MINUTE_IN_MILLIS = 60 * 1000;
export const HOUR_IN_MILLIS = 60 * 60 * 1000;
export const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

export const RECAPTCHA_SITE_KEY = '6LfIA20gAAAAADG2n9XBlpgPL3h-TNQpQlmC5ev2';

