export function showSchool(jobHierarchyId) {
    if (jobHierarchyId === 6) return false;
    return jobHierarchyId === 4 || jobHierarchyId === 5;
}

export function showDistrict(jobHierarchyId) {
    if (jobHierarchyId === 6) return false;
    return jobHierarchyId >= 3;
}

export function isSchoolUser(jobHierarchyId) {
    if (jobHierarchyId === 7) return false;
    return jobHierarchyId === 4 || jobHierarchyId === 5 || jobHierarchyId === 6;
}

export function isSuperUser(jobHierarchyId) {
    return jobHierarchyId === 1;
}

export function isEsuUser(jobHierarchyId){
    return jobHierarchyId === 2;
}

export function isDistrictAdmin(jobHierarchyId) {
    return jobHierarchyId === 3;
}

export function isInstructor(jobHierarchyId) {
    return jobHierarchyId === 4;
}

export function isParaEducatorCollegeStudentOrEarlyChildhood(jobHierarchyId) {
    return jobHierarchyId === 5 || jobHierarchyId === 6;
}

export function isNde(jobHierarchyId) {
    return jobHierarchyId === 7;
}

export function validateEmailIfNde(email) {
    const name_resolution = email.split('@');
    return name_resolution[1] === "nebraska.gov";
}