<template>

  <label for="educationalInstitution">Select an Organization</label>

  <input
    type="text"
    v-model="educationalInstitution"
    class="o-combobox"
    placeholder="Educational Organization"
    v-on:click="showExistingOrganizations = !showExistingOrganizations"
  />
  <ul class="o-data-list" v-if="showExistingOrganizations">
    <li
      class="o-data-list__item"
      v-for="organization in filterOrganizations"
      :key="organization.idOrganization"
      :value="organization.organizationName"
      v-on:click="selectExistingOrg(organization.organizationName)"
    >
      {{ organization.organizationName }}
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { API_URL } from "../../../Constants";
import {sanitizeNonPasswordInput} from "@/SanitizeInput";

export default {
  props: ["stateCode", "countryCode", "institutionName"],
  emits: ["educationalInstitution"],
  data() {
    return {
      idOrganization: "",
      organizations: [],
      selectedOrganization: "",
      educationalInstitution: (this.institutionName !== null && this.institutionName !== undefined && this.institutionName !== "") ? this.institutionName : "",
      showExistingOrganizations: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      auth: "auth/authenticated",
    }),
    filterOrganizations() {
      return this.organizations.filter((organization) => {
        return organization.organizationName
          .toLowerCase()
          .includes(this.educationalInstitution.toLowerCase());
      });
    },
  },
  watch: {
    educationalInstitution(value) {
      this.educationalInstitution = sanitizeNonPasswordInput(value);
      this.updateEducationalInstitution();
    },
    institutionName(value) {
      this.educationalInstitution = sanitizeNonPasswordInput(value);
      this.updateEducationalInstitution();
    },
    stateCode(value) {
      this.getOrganizations();
      this.updateEducationalInstitution();
    }
  },

  methods: {
    async getOrganizations() {
      if (this.countryCode === "US") {
        let post_uri = API_URL + "/outNeOrganization/getByState";
        try {
          const response = await axios.get(
            post_uri + "?_stateShort=" + this.stateCode
          );
          this.organizations = response.data;
        } catch (err) {
          console.error(err);
        }
      } else {
        let post_uri = API_URL + "/outNeOrganization/getByCountry";
        try {
          const response = await axios.get(
            post_uri + "?_countryCode=" + this.countryCode
          );
          this.organizations = response.data;
        } catch (err) {
          console.error(err);
        }
      }
    },
    updateEducationalInstitution() {
      this.$emit("educationalInstitution", this.educationalInstitution);
    },
    selectExistingOrg(value) {
      this.showExistingOrganizations = false;
      this.educationalInstitution = value;
    },
  },
  mounted() {
    this.getOrganizations();
  },
};
</script>

<style scoped>
.o-combobox {
  max-width: auto;
}

.o-combobox__input {
  padding: 0.5rem;
  width: 100%;
  font-size: 15px;
  -webkit-appearance: none;
  border: 1px solid lightslategray;
  border-radius: 3px;
  text-align: left;
  background: white;
}

.o-combobox__input:focus {
  box-shadow: none;
  -webkit-appearance: none;
  outline: 0;
}

.o-data-list {
  margin-top: 0.5rem;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.o-data-list__item {
  margin-top: 0.5rem;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.o-data-list__item:hover {
  opacity: 0.5;
}

.o-data-list__item--selected {
  background: rgba(0, 0, 0.1);
}
span {
  color: red;
}
</style>