<template>
  <label for="stateSelect">Select State:</label>
  <select v-model="selectedState" v-bind="changeSelectedState()" :disabled="stateDisabled">
    <option value="0" selected>Select a State</option>
    <option
        v-for="state in states"
        v-bind:value="state.stateShort"
        :key="state.stateId"
    >
      {{ state.stateName }}
    </option>
  </select>
  <transition name="fade">
    <div v-show="selectedState === 'NE'">
      <EsuSelect
          :idEsu="selectedEsu"
          :idDistrict="selectedDistrict"
          :idSchool="selectedSchool"
          :selfRegistering="selfRegistering"
          :showSchool="showSchool"
          :esuDisabled="esuDisabled"
          :distDisabled="districtDisabled"
          @selectedEsu="changeSelectedEsu($event)"
          @selectedDistrict="changeDistrict($event)"
          @selectedSchool="changeSchool($event)"
      />
    </div>
  </transition>
</template>

<script>
import axios from "axios";

import {API_URL} from "../../../Constants.js";

export default {
  emits: ["selectedState", "selectedDistrict", "selectedSchool", "selectedEsu" ],
  props: ["idEsu", "idDistrict", "idSchool", "selfRegistering", "showSchool", "esuDisabled", "districtDisabled", "schoolDisabled", "stateDisabled"],
  data() {
    return {
      states: [],
      selectedState: "NE",
      educationalInstitution: "",
      selectedEsu: this.idEsu,
      selectedDistrict: this.idDistrict,
      selectedSchool: this.idSchool,
    };
  },
  methods: {
    async getStates() {
      const response = await axios.get(API_URL + "/state/all");
      this.states = response.data;
    },
    changeSelectedState() {
      this.$emit("selectedState", this.selectedState);
    },
    changeDistrict(value) {
      this.selectedDistrict = value;
      this.$emit("selectedDistrict", this.selectedDistrict);
    },
    changeSchool(value) {
      this.selectedSchool = value;
      this.$emit("selectedSchool", this.selectedSchool);
    },
    changeSelectedEsu(value) {
      this.selectedEsu = value;
      this.$emit("selectedEsu", this.selectedEsu);
    }
  },
  watch: {
    idEsu(value){
      this.changeSelectedEsu(value);
    },
    idSchool(value){
      this.changeSchool(value);
    },
    idDistrict(value){
      this.changeDistrict(value);
    },
    idState(value){
      this.selectedStateChanged(value);
    }
  },
  async mounted() {
    this.getStates();

    if (this.idEsu !== this.selectedEsu) {
      this.selectedEsu = this.idEsu;
    } else {
      this.selectedEsu = '-1';
    }
    if (this.idDistrict !== this.selectedDistrict) {
      this.selectedDistrict = this.idDistrict;
    } else {
      this.selectedDistrict = '-1';
    }
    if (this.idSchool !== null) {
      this.selectedSchool = this.idSchool;
    } else {
      this.selectedSchool = '-1';
    }
  },
};
</script>

<style scoped>
select {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 0);
  color: #555;
  border-radius: 10px;
}
</style>