<template>
  <div class="card" style="max-width: 400px; margin: auto; padding: 25px; border: 1px solid black; border-radius: 0;">
    <form @submit.prevent="loginEvent">
      <label for="email">Email:</label>
      <input class="form-control" type="email" v-model="email" placeholder="Email" required/>
      <label for="password">Password:</label>
      <div class="input-group mb-3" >
        <input
            class="form-control"
            :type="passwordFieldType"
            v-model="password"
            placeholder="Password"
            spellcheck="false"
            required
        />
        <div class="input-group-append">
          <span class="input-group-text" @click="changePasswordFieldType" style="border: 1px solid black; color: black;"><i>
            <font-awesome-icon :icon="passwordShowIcon"/></i></span>

        </div>
      </div>
      <button class="button btn-primary" type="submit">Login</button>
    </form>
    <h5>
      Forgot Your Password?
      <br/>
      <router-link :to="{ name: 'EmailRequest' }">Click Here to Request a Password Change</router-link>
    </h5>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {faEye, faEyeSlash} from "@fortawesome/free-regular-svg-icons";
import {useReCaptcha} from "vue-recaptcha-v3";

export default {
  data() {
    return {
      password: "",
      email: "",
      passwordFieldType: 'password',
      passwordShowIcon: faEye
    };
  },

  watch: {
    email(value) {
      this.email = value;
    },
    password(value) {
      this.password = value;
    },
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
    }),
    changePasswordFieldType() {
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
      this.passwordShowIcon = this.passwordFieldType === "password" ? faEyeSlash : faEye;
    },

    async loginEvent() {
      const token = await this.recaptcha();
      let form = {email: this.email, password: this.password, token: token};
      this.signIn(form);
    },
    async recaptcha() {
      await this.$recaptchaLoaded();
      return await this.$recaptcha('login');
    }
  },
};
</script>

<style scoped>
.card {
  position: relative;
  display: flex;
  margin: auto;
  margin-top: 50px;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  max-width: 250px;
}
</style>