<template>
  <div v-if="showReport" id="flex-box">
    <div
      class="card"
      v-for="assignment in assignments"
      :key="assignment.idAssignment"
    >
      <div>
        <div class="card-body">
          <h5>{{ assignment.title }}</h5>
          <div v-if="assignment.pointsPossible === 0">
            <p>No points are required to complete this module</p>
          </div>
          <div v-else-if="assignment.pointsPossible !== null">
            <p v-if="assignment.score !== null">Points: {{assignment.score}} / {{assignment.pointsPossible}}</p>
            <p v-else>Points Possible: {{assignment.pointsPossible}}</p>
          </div>
          <p v-if="assignment.pointsPossible === 0" style="color: green;">No points are required for this module.</p>
          <p v-else-if="assignment.pointsPossible !== null && isNaN((assignment.score / assignment.pointsPossible)) === false">
            Score: {{ (assignment.score / assignment.pointsPossible) * 100 }}%
          </p>
          <p v-if="assignment.score === null">Module has not yet been started.</p>
          <p
            v-else-if="assignment.score / assignment.pointsPossible < 0.7"
            style="color: red"
          >
            Has not completed or failed this module.
          </p>
          <p
            v-else-if="assignment.score / assignment.pointsPossible > 0.7"
            style="color: green"
          >
            Passed This Module
          </p>
          <p
            v-else-if="assignment.pointsPossible === null"
            style="color: green"
          >
            No score for this module
          </p>
          <a
            v-if="assignment.htmlUrl !== null && user.isParaEducator"
            :href="assignment.htmlUrl"
            >View this Quiz in Canvas</a
          >
          <!-- <p v-else>{{assignment.paraEducator.paraFirstName}} {{assignment.paraEducator.paraLastName}} has not submitted this module yet.</p> -->
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isLoading">
    <h3>Checking to see if this para has reports</h3>
  </div>
  <div v-else class="container">
    <h3>This para has not begun their Project Para course work.</h3>
  </div>
</template>

<script>
import axios from "axios";
import { API_URL, APP_URL } from "../../../Constants";
import { mapGetters } from "vuex";

export default {
  props: ["idUser"],
  computed: {
    ...mapGetters({
      auth: "auth/authenticated",
      user: "auth/user"
    }),
  },
  data() {
    return {
      assignments: [],
      canvasRefreshToken: "",
      canvasAuthToken: "",
      showReport: false,
      isLoading: true
    };
  },
  methods: {
    async getAssignments() {
      let request_uri =
        API_URL +
        "/assignments/saveByStudentId?_idStudent=" +
        this.idUser;
      try {
        let response = await axios.get(request_uri);
        this.assignments = response.data;
        if (response.status === 200){
          this.showReport = true;
          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;
        console.error(err);
      }
    },
  },
  async created() {
    this.getAssignments();

  },
};
</script>
