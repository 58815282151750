<template>
  <div className="container-lg">
    <table>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Educational Institution</th>
        <th>State</th>
        <th>Country</th>
        <th>Date Registered</th>
        <th>Reports</th>
        <th>Active/Inactive</th>
      </tr>
      <tr v-for="para in paras" :key="para.idUser">
        <td>
          <router-link :to="{name: 'EditUser', params: { idUser: para.idUser}}">{{ para.firstName }}
            {{ para.lastName }}
          </router-link>
        </td>
        <td>
          {{para.email}}
        </td>
        <!-- Start Ed Org Mapping -->
        <td v-if="para.inNebraska && para.schoolName !== null">
          {{ para.schoolName }}
        </td>
        <td v-else-if="para.inNebraska && para.districtName !== null">
          {{ para.districtName }}
        </td>
        <td v-else-if="!para.inNebraska">
          {{ para.outStateOrgName }}
        </td>
        <td v-else>No Educational Institution Currently Assigned</td>
        <!--         End Ed Org Mapping -->
        <td v-if="para.stateName !== null">{{ para.stateName }}</td>
        <td v-else>No State Associated with this user</td>
        <td>{{ para.countryCode }}</td>
        <td v-if="para.registrationDate !== null">{{
            para.registrationDate
          }}
        </td>
        <td v-else></td>
        <td
            v-if="para.idParaEducator !== null"
        >
          <router-link
              :to="{
              name: 'SingleParaReport',
              params: { idUser: para.idParaEducator },
            }"
          >{{ para.firstName }} {{ para.lastName }}'s
            Reports
          </router-link
          >
        </td>

        <td v-else>{{ para.firstName }} {{ para.lastName }} Currently Has No Reports</td>
        <td>
          <ToggleSwitch
              :isActive="
              para.accountEnabled !== null ? para.accountEnabled : false
            "
              @checked="toggleEnabled(para.email, para.enabled)"
          />
        </td>

      </tr>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import {API_URL} from "../../../Constants";
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      auth: "auth/authenticated",
    }),
  },
  methods: {
    async getParas() {
      const post_uri = API_URL + "/user/listParas";
      try {
        const response = await axios.get(post_uri, {
          headers: {
            Authorization: "Bearer " + this.auth,
          },
        });
        response.data.sort((a, b) =>
            a.lastName > b.lastName ? 1 : -1
        );
        this.paras = response.data;
      } catch (err) {
        console.error(err);
      }
    },
    async toggleEnabled(email, enabled) {
      const put_uri = API_URL + "/user/enableUser";
      if (enabled === null) {
        enabled = false;
      }
      try {
        const response = await axios.put(
            put_uri,
            {
              email: email,
              enabled: enabled,
            },
            {
              headers: {
                Authorization: "Bearer " + this.auth,
              },
            }
        );
      } catch (err) {
        console.error(err);
      }
    },
  },
  data() {
    return {
      paras: [],
    };
  },

  async created() {
    this.getParas();
  },
};
</script>

<style scoped>
.collapsible {
  background-color: aliceblue;
  color: slategrey;
  cursor: pointer;
  padding: auto;
  text-align: center;
  outline: 1px;
  font-size: 15px;
}

.active,
.collapsible:hover {
  background-color: #ccc;
}

.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: aliceblue;
}

.card {
  position: relative;
  display: flex;
  margin: auto;
  margin-top: 50px;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  max-width: 550px;
  max-height: 250px;
  overflow-y: scroll;
}
</style>