<template>
  <h1>Resources</h1>
  <hr/>
  <div style="text-align: left;">
    <h5 style="color: black;">Table of Contents</h5>
    <br/>
    <a href="#gettingStarted">Resources for Getting Started</a>
    <br/>
    <a href="#troubleShooting">Troubleshooting</a>
    <br/>
    <!--    <a href="#privacyPolicy">Privacy Policy</a>-->
    <!--    <br/>-->
    <a href="#requestHelp">More Help</a>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="card" style=" vertical-align: top; padding: 1%" id="gettingStarted">
        <h3 style="color: black;">Resources</h3>
        <!--    <div class="card-body">-->
        <a href="/Project_PARA-Resources_for_Getting_Started.pdf">Project Para - Resources for Getting Started</a>
        <!--    </div>-->
        <!--    <div class="card-body">-->
        <a href="/1_ProjectPARA_Registering_School_Districts_Institutions_3.pdf">Registering School and District
          Institutions</a>
        <!--    </div>-->
        <!--    <div class="card-body">-->
        <a href="/2_ProjectPARA_Registering_Paraeducators.pdf">Registering Paraeducators</a>
        <!--    </div>-->
        <!--    <div class="card-body">-->
        <a href="/3_ProjectPARA_Enrolling_in_Canvas.pdf">Enrolling in Canvas</a>
        <!--    </div>-->
        <!--    <div class="card-body">-->
        <a href="/4_Project_PARA-Completing_&_Accessing_Certificates-2.pdf">Completing & Accessing Certificates</a>
        <!--    </div>-->
      </div>
    </div>
  </div>

  <div id="troubleShooting" style="text-align: left;" class="card">
    <h3 style="color: black;">Common Troubleshooting</h3>
    <div class="card-body" style="color: black;">
      <p>If you are not receiving emails from Project PARA for password resets, registration notifications, etc.; please
        first check the following:</p>
      <ol>
        <li>Ensure that your email is spelled correctly.</li>
        <li>Check your spam/junk inboxes.</li>
        <li>Contact your school or district’s IT support person and confirm that emails from Project PARA are not
          getting caught by a filter or firewall and that the Project PARA domains for MyESU (para.myesu.org), Canvas
          (esucc.instructure.com), and Badgr (badgr.com) are all “whitelisted”.
        </li>
      </ol>
    </div>
  </div>
  <!--  <div id="privacyPolicy" style="text-align: left;">-->
  <!--    <h5>Privacy Policy</h5>-->
  <!--  </div>-->
  <div style="padding: 30px;" id="requestHelp">
    <h2 style="color: black;">Have a more specific question?</h2>
    <router-link :to="{name: 'RequestHelp'}" style="font-size: 36px;">Contact Us</router-link>
  </div>
</template>

<style scoped>
.programbox {
  overflow: auto;
  font-size: 95%;
  width: 240px;
  float: left;
  margin: 1em 0.5em 1em 0.5em;
  padding: 1em;
  word-wrap: break-word;
  line-height: 1.2em;
  color: #000;
  border: 1px dotted blue;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
}

.card {
  position: relative;
  display: flex;
  margin: auto;
  margin-top: 50px;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0);
  border-radius: 0;
  padding: 1%;
  /*max-width: 800px;*/
  /*max-height: 550px;*/
  overflow-y: scroll;
  text-align: left;
  /*display: inline-block*/
}

.progname {
  font-family: "Open Sans", sans-serif;
  margin: 0 auto 0.5em auto;
  color: #29314a;
  text-align: left;
}

/*a {*/
/*  font-weight: bold;*/
/*  opacity: 1;*/
/*  color: #0000ab;*/
/*}*/

</style>