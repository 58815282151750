import axios from "axios";
import {API_URL} from "../../Constants";
import store from '@/store'
import {resolve} from "chart.js/helpers";
import Swal from "sweetalert2";

export default function tokenInterceptors() {
    axios.defaults.baseURL = API_URL;

    axios.interceptors.request.use(
        function (config) {
            const token = store.getters.access_token;
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const original_request = error.config;
            if (error.response.status === 401) {
                if (original_request.url.includes("/auth/login")) {
                    Swal.fire({
                        title: 'Incorrect Credentials',
                        text: 'The password or email was incorrect, please try again.',
                        passwordShowIcon: 'question',
                        confirmButtonText: 'Retry Sign In'
                    })
                } else if (original_request.url.includes("/auth/refreshToken")) {
                    await store.dispatch("SET_USER");
                    await store.dispatch("SET_ACCESS_TOKEN")
                } else if (!original_request._retry) {
                    original_request._retry = true;
                    let user = store.getters["auth/user"]
                    let content = {
                        email: user.contactEmail,
                        refreshToken: store.getters["auth/refresh"]
                    }
                    await store.dispatch("auth/refreshSignIn", content);
                    original_request.headers["Authorization"] = "Bearer " + store.getters['auth/authenticated'];
                    return axios.request(original_request);
                }
                await store.dispatch("auth/signOut");
                return Promise.reject(error);
            } else if (error.response.status === 404) {
                if (original_request.url.includes("/auth/login")) {
                    Swal.fire({
                        title: 'Incorrect Credentials',
                        text: 'The password or email was incorrect, please try again.',
                        passwordShowIcon: 'question',
                        confirmButtonText: 'Retry Sign In'
                    })
                }
            } else if (error.response.status === 400) {
                if (original_request.url.includes("/updatePassword")) {
                    Swal.fire({
                        title: 'Reusing Old Password?',
                        text: 'You cannot reuse your previous 5 passwords.',
                        icon: 'error',
                        confirmButtonText: 'Retry Changing Password'
                    })
                } else {
                    return error.response;
                }

            }
        }

    )
}
