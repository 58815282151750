<template>
  <NavHeader/>
  <div class="container" style="padding-bottom: 50px;">
    <router-view/>
  </div>
  <footer id="footer">
    <p v-if="currentYear === 2022">
      Ⓒ Educational Service Unit Coordinating Council 2022 <span style="color: black"> | </span>
      <router-link :to="{name: 'RequestHelp'}">Contact Us</router-link><span style="color: black"> | </span>
<!--      <router-link :to="{name: 'PrivacyPolicy'}">Privacy Policy</router-link><span style="color: black"> | </span>-->
      <a href="/privacyPolicy">Privacy Policy</a><span style="color: black"> | </span>
      <a href="/tos">Terms of Service</a>
    </p>
    <p v-else>
      Ⓒ Educational Service Unit Coordinating Council {{ currentYear }} |
      <a href="/about">About</a><span style="color: black"> | </span>
      <router-link :to="{name: 'RequestHelp'}">Contact Us</router-link><span style="color: black"> | </span>
      <a href="/privacyPolicy">Privacy Policy</a><span style="color: black"> | </span>
      <a href="/tos">Terms of Service</a>

    </p>
  </footer>
</template>

<script>
import NavHeader from "./views/Navigation/NavHeader.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      timeout: "auth/timeout",
      refreshTimeout: "auth/refreshTimeout",
      authUser: "auth/user",
      refreshToken: "auth/refresh"
    }),
  },
  components: {
    NavHeader,
  },
  // watch: {
  //   now() {
  //     const now = new Date();
  //     if (now.getTime() > parseInt(this.timeout)) {
  //       this.refreshOrSignOut();
  //     }
  //   }
  // },
  // methods: {
  //   ...mapActions({
  //     signOut: "auth/signOut",
  //     refresh: "auth/refreshSignIn"
  //   }),
  //   refreshOrSignOut() {
  //     const now = new Date();
  //     if (this.timeout !== null || this.timeout !== "null") {
  //       if (now.getTime() > parseInt(this.timeout)) {
  //         if (now.getTime() >= parseInt(this.refreshTimeout)) {
  //           this.signOut();
  //         } else {
  //           let refresh_body = {email: this.authUser.contactEmail, refreshToken: this.refreshToken}
  //           this.refresh(refresh_body);
  //         }
  //       }
  //     }
  //   }
  // },
  data() {
    return {
      currentYear: new Date().getFullYear(),
      maintenanceDate: new Date("November 21, 2022"),
      maintenanceTime: "9:30 AM",
      now: new Date()
    };
  },
  // mounted() {
  //   this.refreshOrSignOut();
  // }
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: slategray;
  position: relative;
  min-height: 100vh;
}

label {
  color: rgb(0, 0, 0);
  display: inline-block;
  margin: 25px 0 15px;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  color: #593196;
  background: #2c3e50;
}

#nav a {
  display: block;
  padding: 0.5rem 1rem;
  color: black;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out;
  border-color: skyblue;
  margin: auto auto;
}

#nav a.router-link-exact-active {
  color: black;
  background: rgb(255, 187, 2);
  border-color: #2c3e50;
  border-radius: 10px;
}
#nav a.router-link-exact-active:hover {
  color: white;
  background: #0000ab;
  border-color: #2c3e50;
  border-radius: 10px;
}

#nav a:hover {
  color: white;
}

.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  margin: 30px auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

input {
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid rgb(0, 0, 0);
  color: #555;
  border-radius: 0;
    --maz-border-radius: 0;
}

select {
    border: 1px solid rgb(0, 0, 0);
}
</style>
