<template>
  <button class="btn" @click="getCanvasClientInfo()">Link Project Para Account to Canvas</button>
  <div v-if="canvasBaseUri != ''">
    <a :href="canvasBaseUri">Click This Link to Authorize Project Para With Your Canvas Account</a>
  </div>
</template>

<script>
import { API_URL, APP_URL } from "../../../Constants";
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  computed: {
    ...mapGetters({
      user: "auth/user",
      auth: "auth/authenticated",
    }),
  },
  data() {
    return {
      clientSecret: "",
      clientId: "",
      stateStr: Math.random().toString(36).substring(0, 12),
      canvasBaseUri: "",
    };
  },
  methods: {
    async getCanvasClientInfo() {
      let get_uri = API_URL + "/user/canvasClientSecret";
      localStorage.setItem('canvas_state', this.stateStr);
      const response = await axios.get(get_uri, {
        headers: {
          Authorization: "Bearer " + this.auth,
        },
      });

      this.clientSecret = response.data.client_secret;
      this.clientId = response.data.client_id;
      this.canvasBaseUri =
        "https://esucc.instructure.com/login/oauth2/auth?client_id=" +
        this.clientId +
        "&response_type=code&state=" +
        this.stateStr +
        "&redirect_uri=" + APP_URL;
    },
  },
};
</script>

<style scoped>
.btn {
  background-color: rgba(145, 224, 120, 0);
  font-size: 1.25rem;
}
</style>
