import {API_URL} from "../../../Constants";

const BASE_ROUTE = `${API_URL}/badgr`;

export const getBadgrLaunchUrl = () => {
    return `${BASE_ROUTE}/getLaunchUrl`;
}

export const authWithBadgr = (code) => {
    return `${BASE_ROUTE}/auth?code=${code}`;
}

export const refreshBadgr = (refreshToken) => {
    return `${BASE_ROUTE}/refresh?refreshToken=${refreshToken}`;
}