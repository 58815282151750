import axios from "axios";
import {authWithBadgr} from "@/Helpers/ApiCalls/BadgrAPICalls";

export default {
    namespaced: true,
    state: {
        badgrToken: localStorage.getItem("badgr_token"),
        badgrRefresh: localStorage.getItem("badgr_refresh")
    },
    getters: {
        token(state) {
            return state.badgrToken;
        },
        refresh(state) {
            return state.badgrRefresh;
        }
    },
    mutations: {
        SET_BADGR_TOKEN(state, token) {
            state.badgrToken = token;
            localStorage.setItem("badgr_token", token);
            if (token === "null" || token === null) {
                localStorage.clear();
            }
        },
        SET_BADGR_REFRESH(state, token) {
            state.badgrRefresh = token;
            localStorage.setItem("badgr_refresh", token);
        }
    },
    actions: {
        async getAuth({dispatch}, content) {
            await axios.post(authWithBadgr(content.code))
                .then((result) => {
                    dispatch("setAccess", result.data);
                })
        },
        async setAccess({commit}, content) {
            commit("SET_BADGR_TOKEN", content.access_token);
            commit("SET_BADGR_REFRESH", content.refresh_token);
        }
    }
}