import blacklist from "validator/es/lib/blacklist";
import whitelist from "validator/es/lib/whitelist";
import {EMAIL_REGEX, NAME_REGEX} from "@/ValidConstants";

export const sanitizeNonPasswordInput = (text) => {
    text = blacklist(text, '\\[\\]');
    text = blacklist(text, /\\+/);
    text = blacklist(text, /\$+/)
    return blacklist(text, '\\{\\}');
}
