<template>
  <form @submit.prevent="updatePassword">
    <div class="card">
      <label for="password">Password:</label>
      <input
        :type="passwordFieldType"
        v-model="password"
        placeholder="Password"
        spellcheck="false"
        required
      />
      <button  @click="changePasswordFieldType"> HI<span class="icon is-small is-right"><i :class="{'fa-eye-slash': passwordFieldType === 'text', 'fa-eye': passwordFieldType === 'password'}"></i></span></button>
      <br />
      <span v-if="msg.password">{{ msg.password }}</span>
      <br />
      <label for="passwordConfirm">Confirm Password:</label>
      <input
        type="password"
        v-model="passwordConfirm"
        placeholder="Confirm Password"
        spellcheck="false"
        required
      />
      <br />
      <span v-if="msg.passwordConfirm">{{ msg.passwordConfirm }}</span>
      <button type="submit" class="button btn-primary" v-if="canSubmit()">Update Password</button>
    </div>
  </form>
</template>

<script>
import axios from "axios";
import { API_URL } from "../../../Constants.js";
import { mapGetters } from "vuex";
import Swal from 'sweetalert2';

export default {
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  data() {
    return {
      msg: [],
      password: "",
      passwordConfirm: "",
      containsUpperCase: null,
      containsLowerCase: null,
      containsNumber: null,
      containsSpecialCharacter: null,
      passwordFieldType: "password"
    };
  },
  watch: {
    password(value) {
      this.password = value;
      this.validatePassword(value);
    },
    passwordConfirm(value) {
      this.passwordConfirm = value;
      this.validatePasswordConfirm(value);
    },
  },
  methods: {
    validatePassword(value) {
      let diff = 8 - value.length;
      if (value.length < 8) {
        this.msg["passwordLength"] =
            "Must be 8 characters " + diff + " characters remaining";
      } else {
        this.msg["passwordLength"] = "";
        this.msg["passwordContains"] = "Password must contain:";
        this.containsUpperCase = /[A-Z]/.test(value);
        this.containsLowerCase = /[a-z]/.test(value);
        this.containsNumber = /[0-9]/.test(value)
        this.containsSpecialCharacter = /[#?!@$%^&*-]/.test(value)
      }

      if (this.canSubmit()){
        this.msg["passwordContains"] = "";
      }
    },
    validatePasswordConfirm(value) {
      if (value !== this.password) {
        this.msg["passwordConfirm"] = "Passwords must match!";
      } else {
        this.msg["passwordConfirm"] = "";
      }
    },
    changePasswordFieldType(){
      this.passwordFieldType = this.passwordFieldType === "password" ? "text" : "password";
    },
    canSubmit() {
      return this.msg["passwordLength"] === ""
          && this.msg["passwordConfirm"] === ""
          && this.password !== ""
          && this.containsSpecialCharacter
          && this.containsUpperCase
          && this.containsLowerCase
          && this.containsNumber;
    },
    async updatePassword() {
      const post_data = JSON.stringify({
        email: this.user.contactEmail,
        password: this.password
      })
      const post_uri =
        API_URL +
        "/user/updatePassword";
      try {
        let response = await axios.put(post_uri, 
          post_data,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        if (response.status === 200) {
          this.$router.push({ name: "Login" });
        }
      } catch (err) {
        Swal.fire({
          title: 'Something Went Wrong',
          text: 'Make sure the passwords match!',
          passwordShowIcon: 'error',
          confirmButtonText: 'Retry Changing Password'
        })
      }
    },
  },
  mounted() {
  },
};
</script>
