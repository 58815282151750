<template>
    <div>
        <label for="selectedEsu">Select a Nebraska ESU<span>*</span></label>
        <div>
            <select class="form-select" :tabindex="tabIdx" v-model="selectedEsu" :id="selectedEsu"
                    @change="changeEsu($event)"
                    :disabled="esuDisabled" style="height: 41px;">
                <option value="-1">Select an ESU</option>
                <option v-for="esu in esus" :key="esu.idEsu" :value="esu.idEsu">{{ esu.esuName }}</option>
            </select>
            <div v-if="selectedEsu !== -1 && showDistrict">
                <DistrictSelectComposition
                        :idEsu="selectedEsu"
                        :idDistrict="selectedDistrict"
                        :idSchool="selectedSchool"
                        :districtDisabled="disabledDist"
                        :schoolDisabled="disabledSchool"
                        :changingEsu="updatingEsu"
                        :showSchool="showSchool"
                        @selectedDistrict="updateDistrict($event)"
                        @selectedSchool="updateSchool($event)"
                        :tabIdx="tabIdx + 1"
                />
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {computed, onBeforeMount, ref, watch} from "vue";
import {API_URL} from "../../../Constants";
import SchoolSelectComposition from "@/components/LocationSelect/SchoolSelectComposition";

import DistrictSelectComposition from "@/components/LocationSelect/DistrictSelectComposition";

export default {
	name: "EsuSelectComposition",
	components: {DistrictSelectComposition},
	// emits: ["selectedSchool", "selectedEsu", "selectedEsu"],
	props: {
		esuDisabled: Boolean,
		districtDisabled: Boolean,
		schoolDisabled: Boolean,
		selfRegistering: Boolean,
		showDistrict: Boolean,
		showSchool: Boolean,
		idEsu: Number,
		idDistrict: Number,
		idSchool: Number,
		tabIdx: {
			type: Number,
			required: false,
			default: 1,
		},
	},
	setup(props, context) {
		let selectedEsu = ref(-1);
		let selectedDistrict = ref(-1);
		let selectedSchool = ref(-1);

		let disabledEsu = ref(false);

		watch(() => props.esuDisabled, () => {
			disabledEsu.value = props.esuDisabled;
		})

		const disabledDist = computed(() => (props.districtDisabled));

		const disabledSchool = computed(() => {
			return props.schoolDisabled;
		})

		const updatingEsu = computed(() => {
			return props.idEsu !== selectedEsu.value;
		})

		watch(() => props.idEsu, () => {
			selectedEsu.value = props.idEsu;
		})

		watch(() => props.idDistrict, () => {
			selectedDistrict.value = props.idDistrict;
		})

		watch(() => props.idSchool, () => {
			selectedSchool.value = props.idSchool;
		})

		onBeforeMount(() => {
			getEsus();
			setDefaultVals();
		})

		let esus = ref(null);

		function setDefaultVals() {
			if (props.idDistrict !== -1) {
				selectedDistrict.value = props.idDistrict;
			}

			if (props.idSchool !== -1) {
				selectedSchool.value = props.idSchool;
			}
		}

		function changeEsu(event) {
			selectedEsu.value = parseInt(event.target.value);
			context.emit("selectedEsu", selectedEsu.value);
		}

		async function getEsus() {
			// let self_registering = props.selfRegistering ? btoa('true') : btoa('false');
			let get_uri = API_URL + "/esu/all?_isDisabled=" + btoa('false');
			await axios.get(get_uri)
				.then((result) => {
					esus.value = result.data;
				});

			if (props.idEsu !== null && props.idEsu !== undefined && props.idEsu !== -1) {
				selectedEsu.value = props.idEsu;
			}
		}

		function updateSchool(event) {
			selectedSchool.value = parseInt(event);
			context.emit("selectedSchool", event);
		}

		function updateDistrict(event) {
			selectedDistrict.value = parseInt(event);
			context.emit("selectedDistrict", event)
		}

		return {
			esus,
			selectedEsu,
			disabledDist,
			disabledSchool,
			selectedDistrict,
			selectedSchool,
			disabledEsu,
			changeEsu,
			updateSchool,
			updateDistrict,
			updatingEsu
		}
	}

}
</script>

<style scoped>
span {
    color: red;
}
</style>